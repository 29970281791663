import React from 'react'
import {GoogleMap, useJsApiLoader} from '@react-google-maps/api';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
//import Autocomplete from "react-google-autocomplete";
import { useEffect } from 'react';
import {getRestaurantsAddresses} from '../utils/fetcher'
import { useState } from 'react';
import Modal from './Modal';
//import { restaurants } from '../restData/restData';
import { restaurants } from '../restData/restDataPartial';
import restPlaceIcon from '../images/restPlaceIcon.png'
import restPlaceIconStart2 from '../images/restPlaceIconStart2.png';
import {pol1,pol1Tamir, pol2Tamir,pol3Tamir3, pol4Tamir, pol5Tamir, pol6Tamir, pol7Tamir} from '../polygons/polygons'
import all_TA, { All_TA } from "../polygons/All_TA_polygon";
import { useTranslation } from 'react-i18next';
import Form from './Form';
import LinksToApps from './LinksToApps';

const mapKey = process.env.REACT_APP_GOOGLEMAPKEY;


const containerStyle = {
    
    height: '600px',
    margin:'0 auto',
    display:'block',
    borderRadius:'24px',
    
    
  };
  
export default function Map() {
    const [t,i18n] =useTranslation("global");
  
    //const [restaurants1,setRestaurants] = useState([]);
    let rest = restaurants;
    const [ libraries ] = useState(['places']);
    //const [cityInput,setCityInput] = useState('');
    //const [streetInput,setStreetInput] = useState('');
    //const [streetNumberInput,setStreetNumberInput] = useState('');
    const [modalBtnText,setModalBtnText] = useState('');
    const [modalText,setModalText] = useState('');
    const [mapGlobal,setMapGlobal] = useState('');
    const [centerMap,setCenterMap] = useState({lat:32.074855, lng:34.775897}) //32.074855, 34.775897
    const [moveTo,setMoveTo] = useState({});
    const [mapZoom,setMapZoom] = useState(14);
    const [hideSearchFieldAndBtn,setHideSearchFieldAndBtn] = useState(true);
    const [hideForm,setHideForm] = useState(true)
    const [clickCloseModal,setUserClickModal] = useState(false);
    const [modal,setModal] = useState(false);
    const [addressInput,setAddressInput] = useState('');
    const [addressResultsObj,setAddressResultsObj] = useState();
    const [routingActions,seRoutingActions] = useState('');


  function CloseModalHandler(){
    setModal(false);
    map.panTo(moveTo)
  
  }

  function userClickClose(){
  }


    useEffect(()=>{
     // setAddressInput(cityInput + ' ' + streetInput + ' ' + streetNumberInput);
     
    },[])//[cityInput,streetInput,streetNumberInput,addressInput]);
    

    


    const select = async (addressString) => {
      try {
        const results = await geocodeByAddress(addressString);
  
        if (!results.length) {
          console.log('Address not found');
          return;
        }
  
        const latLng = await getLatLng(results[0]);
  
        if (!latLng.lat || !latLng.lng) {
         console.log('Address not found');
          return;
        }
  
        let street_number =
          results?.[0]?.address_components?.find((v) => v.types.includes('street_number'))?.long_name ?? '';
        let street = results?.[0]?.address_components?.find((v) => v.types.includes('route'))?.long_name ?? '';
        let city = results?.[0]?.address_components?.find((v) => v.types.includes('locality'))?.long_name ?? '';
  
        let address = `${results?.[0]?.formatted_address}`;
        // console.log( street_number);
        // console.log( street);
        // console.log( city);
        // console.log( Object.values(latLng).reverse());

  
       // setQuery(address); // show full address but return only the street
      // setStreetInput(address);
      setAddressInput(address)
      setAddressResultsObj({
        formatted_address:address,
        address: `${street} ${street_number}`,
				city,
				coordinates: Object.values(latLng).reverse()
      });
      } 
      catch (err) {
        console.log('Addresses not found');
        return;
      }
    }; 

  
    const [lng,setLng] = useState('he')
    
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: mapKey,
        language:lng,
        libraries,
        
      }
       
      )

      const [map, setMapCustom] = useState(null);
      const [TA,SetTA] = useState();
      const [marker,setMarker] = useState(null);
      
      
      
      const onLoad = React.useCallback(function callback(map) {
       
        
        const iconBase ="https://developers.google.com/maps/documentation/javascript/examples/full/images/";


  const TA_North = new window.google.maps.Polygon({
    paths:[pol1Tamir,pol7Tamir],
    //paths:[All_TA],
    strokeColor: "#FF0000",
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: "#FF0000",
    fillOpacity: 0.2,
    clickable:false
    
    
  });
  SetTA(TA_North);

       TA_North.setMap(map);
       setMapCustom(map);
      }, [])
    
      const onUnmount = React.useCallback(function callback(map) {
        setMapCustom(null)
      }, [])

    //   checking text input
    function checkIfInsideArea(){
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode( { 'address':addressInput}, function(results, status) {
        if (status == 'OK') {
            var latlng =new window.google.maps.LatLng(results[0].geometry.location.lat(),results[0].geometry.location.lng());
            const checkIfContains = window.google.maps.geometry.poly.containsLocation(
                latlng,
                TA);
              
              if(checkIfContains){
              setModalText(t("Modal.AmazingWeInYourArea"));
              setModalBtnText(t("Modal.JoinNow"));
              seRoutingActions("JoinNow")
              

              }
              else{
               setModalText(t("Modal.SorryWeNotInYourArea"));
               setModalBtnText(t("Modal.JoinAnyway"));
               seRoutingActions("JoinAnyway")
               
              }
              const mark = new window.google.maps.Marker({
                position:latlng,
                map,
                title: addressInput,
              });
             mark.setMap(map);
            // setMapGlobal(map);
             setMoveTo({lat:results[0].geometry.location.lat(),lng:results[0].geometry.location.lng()});
             setModal(true)
        } else {
          // alert('Geocode was not successful for the following reason: ' + status);
          alert(t("Alerts.CorrectAddress"));
        }
      });
  }

 


    //end   checking text input




    
      return isLoaded ? (
        <div>
          <div>
         {modal &&<> <Modal 
         text={modalText} 
         btnText={modalBtnText}
         hideSearchFieldAndBtn={setHideSearchFieldAndBtn} 
         hideForm={setHideForm}
         userClickClose ={userClickClose}
         addressObj={addressResultsObj}
         RoutinActions={routingActions}
         closeModalHandler  = {CloseModalHandler} /></>}
          </div>
          <div >
           <div>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={centerMap}   
            zoom={mapZoom}
            onLoad={onLoad}
            onUnmount={onUnmount}>
          </GoogleMap> 
          </div>
          </div>
          
          <div className={`mt-6 sm:mt-6 sm:text-right ${!hideSearchFieldAndBtn? 'invisible' : ''}`}>
            <div className='mb-4'><h1 className='text-xl'>{t("MapSearchPlaceHolderHeaderText")}</h1></div>
            <PlacesAutocomplete
            debounce={1000}
           searchOptions={{
            types: ['address'],
            language:'he',
            componentRestrictions: { country: ['IL'] },
            
          }}
            value={addressInput}
           // onChange={(e) =>setStreetInput(e)}
           onChange={(e) =>setAddressInput(e)}
           
            onSelect={select}
            >
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div style={{maxWidth:'666px'}}>
            <input
            
              style={{width:'100%',padding:'15px',borderRadius:'100px',border:'1px solid black',fontSize:'1rem'}}
              {...getInputProps({
                placeholder: t("MapSearchPlaceHolder"),
                className: 'location-search-input',
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                  key={suggestion.placeId}
                  
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
            </PlacesAutocomplete>
          </div>
          
         <div className={`mt-4 sm:mt-6 sm:text-start ${!hideSearchFieldAndBtn? 'invisible' : ''}`}>
              <div >
              <button onClick={() =>{checkIfInsideArea()}} 
              className="text-xl py-4 px-10 hover:bg-blue-700 text-white font-bold rounded-full buttonText sm:w-250 w-full" 
              style={{backgroundColor:'#1F1546'}}
              
              >{t("MapSErchBtnText")}</button>
              </div> 
             
            </div>
            <div id='formRef'>
            {!hideForm &&  <Form/>}
          </div>
          </div>
      ) : <></>

    
   
  }