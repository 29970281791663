import {useNavigate,useLocation,withRouter} from 'react-router-dom';
import logo from '../images/Logo.png';
import washLogo from '../images/WASH.png'
import { useEffect, useState } from 'react';
import Form from './Form';
import JoinBtn from './JoinBtn';
import appImage from '.././images/app_background.png'
import { useTranslation } from 'react-i18next';
import LanguagesBtns from './LanguagesBtns';
import { getLocationPath} from './../utils/pathChecker';
import { ScrollToBtn } from './ScrollToBtn';


export default function Hero() {

  const [t,i18n] =useTranslation("global");


  const navigate = useNavigate();
  const location = useLocation();
  const [heroDescText,setHeroDescText] = useState(t("header.heroDescText"));
  const [heroMainText,setHeroMainText] = useState(t("header.heroText"));
  const [showDescTextScndPart,setShowDescTextScndPart] = useState(true);
  const [joinNow,setJoinNow] = useState(true);
  const [heroTextRtl,setTextRtl] = useState('sm:text-right');
  const [heroAppImage,setHeroAppImage] = useState('left-0');
  const [marginHero,setMarginHero] =useState('mx-4 xl:mr-64');
  const [showLngBtns,setShowLngBtns] = useState(false);
  const [lngBtnsSide,setLngBtnsSide] = useState('text-xl sm:text-2xl absolute left-6 top-6');








  const [sublogo,setSublogo] = useState(false);
  const path = getLocationPath()


  useEffect(()=>{
    if (path == '/customers') {
      setSublogo(false);
      // setHeroDescText(t("header.heroDescText"));
      // setHeroMainText(t("header.heroText"));
      setJoinNow(true);
    }
    if(path == '/article'){
      setSublogo(true);
      setHeroMainText(t("header.heroDescText2"));
      setHeroDescText('');
      setShowDescTextScndPart(false);
      setJoinNow(false);

    }

  if(path == '/wewash')
  {
    
    setSublogo(true);
    setHeroDescText(t("header.heroWeWashText"));
    setShowDescTextScndPart(false);
    setHeroMainText('');
    setShowDescTextScndPart(false);
    
  }
  if(path == '/form' || path == '/ImmediateOrderForm')
  {
    setShowDescTextScndPart(false);
    setSublogo(false);
    setHeroDescText('')
    setHeroMainText('');
    setJoinNow(false);
  }
  if(path == '/AccesibilityText')
  {
    setJoinNow(false);
    setSublogo(false);
    setHeroDescText('')
    setHeroMainText(false);


  }
  checkLngDir();

  if(path == '/employes'){
    setShowLngBtns(true);
    handleChhangeLanguage('en')
    setShowDescTextScndPart(false);

  }
  if(path == '/customers')
  {
    setShowLngBtns(false);
  }
  },[window.location.pathname])

  window.onpopstate = function() {
    NavigateToHome();
  };

  function NavigateToHome(){
    navigate('/');
    window.location.reload(false);
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' })

  }

  const checkLngDir= () => {
    if(document.body.dir == 'rtl'){
      setHeroMainText(t("header.heroText"))
      setHeroDescText(t("header.heroDescText"))
      setTextRtl('sm:text-right');
      setHeroAppImage('left-0');
      setMarginHero('mx-4 xl:mr-64')
      setLngBtnsSide('text-xl sm:text-2xl absolute left-6 top-6')
    }
    if(document.body.dir == "ltr"){
      setHeroMainText(t("header.heroText"))
      setHeroDescText(t("header.heroDescText"))
      setTextRtl('sm:text-left');
      setHeroAppImage('right-0')
      setMarginHero('mx-4 xl:ml-64')
      setLngBtnsSide('text-xl sm:text-2xl absolute right-6 top-6')
    }
  }
  const handleChhangeLanguage = (lang) =>{
    i18n.changeLanguage(lang);
    document.body.dir = i18n.dir(lang);
    checkLngDir();

  }




    return (
      <div className={marginHero} >
        {showLngBtns &&
        <div className={`font-Monserrat flex gap-x-2 font-semibold text-3xl`}
        style={{color:'#FA8416'}}>
        {/* <div><button onClick={()=>handleChhangeLanguage("he")}>Hebrew</button></div>
        <div><button onClick={()=>handleChhangeLanguage("en")}>English</button></div> */}
        <LanguagesBtns handleChangeLanguage ={handleChhangeLanguage}/>
        </div>}

         <div>
        <div className='2xl:w-60 w-[110px] pt-24  w-36  sm:w-80 sm:pt-36 m-auto sm:m-1'
        style={{minWidth:'107px'}}
        onClick={NavigateToHome}>
      <img className=''  src={logo} alt='logo'/>
       </div>

      </div>
      {sublogo &&
      <div>
        <div className='w-1/2 mt-2 w-[110px] mb-12 sm:w-80 w-36 2xl:w-60  sm:mt-1 m-auto sm:m-1'>
      <img  src={washLogo} alt='logo'/> </div>
      </div>
      }

      <div className='pl-0 lg:w-full xl:max-w-[1000px]'>
        <div>
      <div className={`main_header_text mt-5 text-xl sm:mt-24 sm:text-5xl sm:max-w-4xl text-center ${heroTextRtl}`}>
        <p>{heroMainText}</p>


      </div>
      <div className={`main-slogan mt-5  text-base sm:text-2xl sm:max-w-55 text-center ${heroTextRtl}`}>
       <p>{heroDescText}</p><br/>
       {showDescTextScndPart &&
       <p>{t("header.heroDescText1")}</p>
            }
      </div>
      <div className={`main-slogan mt-5  text-base sm:text-2xl sm:max-w-55 text-center ${heroTextRtl}`}>
      
       {showDescTextScndPart &&
       <p>{t("header.heroDescText3")}</p>
            }
      </div>
      <div className={`main-slogan mt-5  text-base sm:text-2xl sm:max-w-55 text-center ${heroTextRtl}`}>
       
       {showDescTextScndPart &&
       <p>{t("header.heroDescText4")}</p>
            }
      </div>
      <div className='flex mt-12 sm:mt-16 flex-wrap gap-x-3 justify-center sm:justify-start'>
        {joinNow ?
        <>

        <div className='mb-3'> <ScrollToBtn text={t("BtnText.joinNow")} 
        classes="py-4 px-8  rounded-full cursor-pointer" 
        btnTxtStyle ="text-white buttonText"/></div>
        {/* <span className=''>
          <JoinBtn text='הזמנת מעל 9 עובדים' linkUrl='immediateOrder' showQuestion={true}/>
          </span> */}
        </>
        : null}
      </div>

      </div>
      <div className='hidden lg:block lg:hidden 2xl:block xl:hidden ' style={{overflow:'hidden'}}>
        <img className={` xl:max-w-[32%] 2xl:max-w-[28%] ${heroAppImage}` } src={appImage} style={{position:'absolute',zIndex:-1000,bottom:'0px'}}/>
      </div>
      </div>
      </div>
    );
  }