import washIconCircle from '../images/washIconCircle.png'
import cleanIconCircle from '../images/cleanIconCircle.png'
import cookIconCircle from '../images/cookIconCircle.png'


const vacanciesItemsData = [
    {
    key:1,
    icon:washIconCircle,
    altText:'wash icon',
    text:'שוטף כלים',
    },
    {
    key:2,
    icon:cleanIconCircle,
    altText:'clean icon',
    text:'עובד כללי / נקיון',
    },
    {
    key:3,
    icon:cookIconCircle,
    altText:'cook icon',
    text:'עוזר טבח',
    },
    
        




]
export default vacanciesItemsData;  