
import ourAdvantagesItemsData from "../ourAdvantagesData/ourAdvantagesData";
import OurAdvantagesItem from "./OurAdvantagesItem";
import { useTranslation } from 'react-i18next';


export default function OurAdvantages() {
  const [t,i18n] =useTranslation("global");
    
    return (
      <div className="max-w-[1365px] m-auto mx-4" >
      <div className="w-full py-10 sm:mt-[150px] mt-[100px] flex " >
        <div className="">
        <div className="font-Heebo font-medium text-lg sm:text-5xl sm:mb-16 mb-8" style={{color:'white'}}>
            <span style={{color:'#1F1546'}}>{t("OurAdvantagesText")}</span>
        </div>
        <div className="flex flex-wrap text-center justify-center">
          
        {
            ourAdvantagesItemsData.map((item)=>{
                return <OurAdvantagesItem key ={item.id} {...item} title ={t(`OurAdvantages.T`+ item.id + ".Title")} text ={t(`OurAdvantages.T`+ item.id + ".Text")}/>
               
            })
        }
        </div>
        </div>
      </div>
      </div>
    );
  }