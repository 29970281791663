

export default function VacancyItem(props) {
    
    return (
      <section className="mb-8">
        {/* <div >   style={{minWidth:'215px'}} */}
        <div className="w-[100px] sm:w-40">
        <img className="mx-auto" src ={props.icon}></img>
        </div>
        <div className="vacanciesText w-15 mx-auto">
          <span>{props.text}</span>
        </div>
      </section>
    );
  }