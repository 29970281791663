import React from 'react'

export function DirectMailerMapPol6(props) {
    

    return (
        <div>
          <iframe src="https://www.google.com/maps/d/u/1/embed?mid=1dt7U8_lI1rz-97PN-4dkZv4ehYmp12Y&ehbc=2E312F&noprof=1" width="100%" height="800px"></iframe>
        </div>
    )
}
