import haimWithFriends from "../images/HaimWithFriends.png"
import Vacancies from "./Vacancies";
import JoinBtn from "./JoinBtn";
import { Outlet } from "react-router-dom";
import EmbeddedVideo from "./EmbeddedVideo";
import { useTranslation } from 'react-i18next';
import { ScrollToBtn } from './ScrollToBtn';

export default function Testimonials() {
  const [t,i18n] =useTranslation("global");
    
    return (
      <div className="">
      <div className="innerWrapper">
        {/* <div className="font-Heebo font-medium text-lg sm:text-5xl sm:mb-16 mb-4">
           <span style={{color:'#1f1546'}}>
            {t("OurModel")}
           </span>
        </div> */}
        <div className="flex justify-between sm:gap-x-8">
            <div >
                <div 
                className="max-w-6xl ourModel_paragraph text-base sm:text-2xl  text-start">
                  <div>
                    <p>
איש העסקים חיים פנחס השיק בחמישי האחרון 22/02/24 את הסטרטאפ שפיתח בשנתיים האחרונות פלטפורמה דיגיטלית להעסקת עובדים זרים כיבדו בנוכחותם רני רהב, אילן פיבקו, יהושוע וענת קסטיאל, רחביה וגליה קסוטו, עו"ד שבי מיכאל ממשרד ליפא מאיר ועוד חברים .
                    </p>
                    <p className="mt-6 sm:mt-6">
                    פנחס שמחזיק היום בית תוכנה המתמחה בפיתוח פלטפורמות להעסקת עובדים זרים היה נרגש במיוחד לאור הפיתוח הייחודי של הפלטפורמה היחידה מסוגה בעולם אשר דואגת לזכויות העובדים הזרים בכל המקצועות בהם הם מועסקים .
                    </p>
                    <p className="mt-6 sm:mt-6">
זאת זכות גדולה שקיבלתי לזהות את מצוקתם של העובדים הזרים ובהפרת זכיותיהם ולהביא פתרון טכנולוגי שסוף סוף יבטיח את מלוא תשלום השכר שלהם באופן חוקי תוך הנגשת אלפי מישרות באופן ישיר בין העובד הזר ומקבל השירות
                    </p>
         
</div>
       
            </div>

            <div 
            className="w-full h-96 bg-no-repeat bg-cover mt-9 sm:hidden"
            style={{backgroundImage:`url(${haimWithFriends})`}}
           >
                    {/* <img className="object-contain" src={workerImg} alt ="worker cooking"></img> */}
                    </div>
            
            </div>
        
           
            <div className="workerImage hidden sm:block max-w-[400px]">
                    <img className="object-contain" src={haimWithFriends} alt ="worker cooking"></img>
                    </div>

        </div>
       
                 
      </div>
      </div>
    );
  }