import React, { useState } from 'react';
import ImageGallery from "react-image-gallery";
import firstI from '../images/StartFront/1.jpeg';
import secondI from '../images/StartFront/2.jpeg';
import thirdI from '../images/StartFront/3.jpeg';

const images = [
    {
      original: firstI,
      thumbnail: firstI,
      thumbnailClass: "mx-3 mt-2",
      
     
    },
    {
        original: secondI,
        thumbnail: secondI,
        thumbnailClass: "mx-3 mt-2",
       
    },
    {
        original: thirdI,
        thumbnail: thirdI,
        thumbnailClass: "mx-3 mt-2",
        
    },
  ];

const ImagesSlider = () => {

    const [showNav,setShowNav] = useState(false)
    const [thumbnail,setThumpNail] = useState(500)



  return (
  <div>
    <ImageGallery   items={images} />
  </div>)
}

export default ImagesSlider;
