


export default function AccesibilityText() {
    
    return (
      <section dir="rtl">
        <div className="w-1/3 flext m-auto ">
            <h1 className="text-4xl font-Heebo">
        הצהרת נגישות
                </h1>
                <p>
                    WE Wash, אחראית על הקמת והפעלת אתר (<strong> start2.co.il</strong>). אנו רואים חשיבות רבה במתן שירות שוויוני לכלל האזרחים ובשיפור השירות הניתן לאזרחים עם מוגבלות.
                    &nbsp;אנו משקיעים משאבים רבים בהנגשת האתר והנכסים הדיגיטליים שלנו על מנת להפוך את שירותי החברה לזמינים יותר עבור אנשים עם מוגבלות.
                    במדינת ישראל כ-20 אחוזים מקרב האוכוסייה הינם אנשים עם מוגבלות הזקוקים לנגישות דיגיטלית, על מנת לצרוך מידע ושירותים כללים.
                    הנגשת האתר של Vee, נועדה להפוך אותו לזמין, ידידותי ונוח יותר לשימוש עבור אוכלוסיות עם צרכים מיוחדים, הנובעים בין היתר ממוגבלויות מוטוריות שונות,
                לקויות קוגניטיביות, קוצר רואי, עיוורון או עיוורון צבעים, לקויות שמיעה וכן אוכלוסייה הנמנית על בני הגיל השלישי.
                </p>
                
(WE Wash), אחראי על הקמת והפעלת אתר (<strong> start2.co.il</strong>). אנו רואים חשיבות רבה
במתן שירות שוויוני לכלל האזרחים ובשיפור השירות הניתן לאזרחים עם מוגבלות.
אנו משקיעים משאבים רבים בהנגשת האתר והנכסים הדיגיטליים שלנו על מנת להפוך את
שירותי החברה לזמינים יותר עבור אנשים עם מוגבלות.
במדינת ישראל כ-20 אחוזים מקרב האוכלוסייה הינם אנשים עם מוגבלות הזקוקים לנגישות
דיגיטלית, על מנת לצרוך מידע ושירותים כללים.
הנגשת האתר של (WE Wash), נועדה להפוך אותו לזמין, ידידותי ונוח יותר לשימוש עבור
אוכלוסיות עם צרכים מיוחדים, הנובעים בין היתר ממוגבלויות מוטוריות שונות,
לקויות קוגניטיביות, קוצר רואי, עיוורון או עיוורון צבעים, לקויות שמיעה וכן אוכלוסייה הנמנית
על בני הגיל השלישי.
הנגשת אתר זה בוצעה על ידי חברת הנגשת האתרים &quot;Vee הנגשת אתרים&quot;.
רמת הנגישות באתר - AA
חברת &quot;Vee&quot;, התאימה את נגישות האתר לדפדפנים הנפוצים ולשימוש בטלפון הסלולרי ככל
הניתן, והשתמשה בבדיקותיה בקוראי מסך מסוג Jaws ו- NVDA.
מקפידה על עמידה בדרישות תקנות שוויון זכויות לאנשים עם מוגבלות 5688 התשע&quot;ג
2013 ברמת AA. וכן, מיישמת את המלצות מסמך WCAG2.2 מאת ארגון W3C.
בעברית: WCAG 2.0 הנחיות לנגישות תכנים באינטרנט
באנגלית:  0Web Content Accessibility Guidelines (WCAG) 2.
הנגשת האתר בוצעה בהתאם להנחיות רשות התקשוב להנגשת יישומים בדפדפני אינטרנט.

כיצד עוברים למצב נגיש?
באתר מוצב אייקון נגישות (בד&quot;כ בדפנות האתר). לחיצה על האייקון מאפשרת פתיחת של
תפריט הנגישות. לאחר בחירת הפונקציה המתאימה בתפריט יש להמתין לטעינת הדף
ולשינוי הרצוי בתצוגה (במידת הצורך).
במידה ומעוניינים לבטל את הפעולה, יש ללחוץ על הפונקציה בתפריט פעם שניה. בכל מצב,
ניתן לאפס הגדרות נגישות.
התוכנה פועלת בדפדפנים הפופולריים: Chrome, Firefox, Safari, Opera בכפוף (תנאי
יצרן) הגלישה במצב נגישות מומלצת בדפדפן כרום.
האתר מספק מבנה סמנטי עבור טכנולוגיות מסייעות ותמיכה בדפוס השימוש המקובל
להפעלה עם מקלדת בעזרת מקשי החיצים, Enter ו- Esc ליציאה מתפריטים וחלונות.

לצורך קבלת חווית גלישה מיטבית עם תוכנת הקראת מסך, אנו ממליצים לשימוש בתוכנת
NVDA העדכנית ביותר.

תיקונים והתאמות שבוצעו באתר:
התאמה לקורא מסך - התאמת האתר עבור טכנולוגיות מסייעות כגון NVDA , JAWS
אמצעי הניווט באתר פשוטים וברורים.
תכני האתר כתובים באופן ברור, מסודר והיררכי.
האתר מותאם לצפייה בדפדפנים מודרניים.
התאמת האתר לתצוגה תואמת מגוון מסכים ורזולוציות.
כל הדפים באתר בעלי מבנה קבוע (1H/2H/3H וכו&#39;).
לכל התמונות באתר יש הסבר טקסטואלי חלופי (alt).

פונקציונליות תוכנת נגישות:
התאמה לקורא מסך - התאמת האתר עבור טכנולוגיות מסייעות כגון NVDA , JAWS
עצירת הבהובים - עצירת אלמנטים נעים וחסימת אנימציות
דילוג ישיר לתוכן - דילוג על התפריט הראשי ישירות אל התוכן.
התאמה לניווט מקלדת
הגדלה / הקטנה של טקסט
ריווח בין אותיות / מילים / שורות
ניגודיות וצבע - גבוהה, הפוכה, שחור לבן
גופן קריא
הדגשת קישורים
מדריך קריאה
שינוי אייקון סמן עכבר
תיאור לתמונות

החרגות
חשוב לציין, כי למרות מאמצינו להנגיש את כלל הדפים והאלמנטים באתר, ייתכן שיתגלו
חלקים או יכולות שלא הונגשו כראוי או שטרם הונגשו.
אנו פועלים לשפר את נגישות האתר שלנו כל העת, כחלק ממחויבותנו לאפשר לכלל
האוכלוסייה להשתמש בו, כולל אנשים עם מוגבלות.

סעיף &quot;נגישות עסק פרונטלי&quot; חובה רק לעסק שמקבל קהל פרונטלי
(חנות פיזית/משרדי מכירות/מסעדות/ אולמות וכו&#39;)
במידה והעסק הוא 100% אונליין/לא מקבל קהל, יש למחוק את
הסעיף הנ&quot;ל (כולל משפט זה)מהצהרת הנגישות!!!!!
נגישות עסק פרונטלי
חניית נכים – יש/אין
גישה נגישה לעסק (עבור כיסא גלגלים) – יש/אין
שירותי נכים – יש/אין
מעליות – יש/קומת קרקע

יצירת קשר בנושא נגישות
במידה ונתקלתם בבעיה בנושא נגישות באתר, נשמח לקבל הערות ובקשות באמצעות פנייה
לרכז הנגישות שלנו:
על מנת שנוכל לטפל בבעיה בדרך הטובה ביותר, אנו ממליצים מאוד לצרף פרטים מלאים
ככל שניתן:
 תיאור הבעיה.
 מהי הפעולה שניסיתם לבצע.
 קישור לדף שבו גלשתם.
 סוג הדפדפן וגרסתו.
 מערכת הפעלה.
 סוג הטכנולוגיה המסייעת (במידה והשתמשתם).
(WE Wash) תעשה ככל יכולה על מנת להנגיש את האתר בצורה המיטבית ולענות לפניות
בצורה המקצועית והמהירה ביותר.
רכז נגישות:
(שם + שם משפחה)
(טלפון)
(מייל)

תאריך עדכון הצהרת נגישות (תאריך מילוי הטופס)
   </div>
   <div>
    
   </div>
      </section>
    );
  } 
  