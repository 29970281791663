import React from 'react'
import { Logo } from '../Logo'

export function PrivacyPolicyHeb() {
    

    return (
        <div className='mb-36 mx-auto' style={{maxWidth:'700px',paddingLeft:'16px',paddingRight:'16px'}}>
          <div className='flex justify-center'>
            <Logo/>
          </div>
            <div className='mt-10'>
        <h1 className='text-2xl sm:text:3xl text-center mb-6'>מדיניות פרטיות</h1>
            <p>
            מ.ח.ר ווי אר טק אייץ&#39;-אר בע&quot;מ (להלן: &quot;החברה&quot;) מספקת, במסגרת שירותיה,
פלטפורמה (להלן: &quot;האפליקציה&quot;) המאפשרת למסעדות, מועדונים, בתי קפה ו/או כל
מוסד אחר המגיש מזון ו/או משקאות המשתמשים בגרסת הלקוחות של האפליקציה
(להלן ביחד: &quot;הלקוחות&quot;) להתקשר עם ספקי שירותים למטרות אספקת שירותי שטיפת
כלים זמניים או מתמשכים (ו/או כל עבודות מטבח אחרות) (להלן: &quot;השירותים
המקצועיים&quot;) ואשר מאפשרת לספקי שירותים לקבל הצעות למתן שירותים מקצועיים.
למטרות מדיניות פרטיות זו המונח &quot;ספקי השירותים&quot; יכלול אנשים הרשומים כנותני
שירות, ספקי שירות פוטנציאליים, או כל מי שמבקר באתר החברה או מוריד את גרסת
האפליקציה של ספקי השירותים. כל סוגי השירותים הניתנים על ידי החברה ללקוחות
ו/או לנותני השירותים, יכונו להלן &quot;שירותי הפלטפורמה&quot;. הלקוחות ונותני השירות יכונו
ביחד &quot;המשתמשים&quot;.
            </p>
            <br/>
            <p>
            על מנת להציע, לפתח, לספק, לפרסם ולתחזק את שירותי הפלטפורמה, החברה אוספת
ומעבדת מידע, לרבות )אך לא רק( מידע אישי המזהה, מתייחס, מתאר, שניתן לשייך,
אותו שניתן לקשר אותו באופן סביר, במישרין או בעקיפין, עם ספק שירות מסוים (להלן:
&quot;המידע&quot;).
בפרט, החברה אוספת את הקטגוריות הבאות של מידע מספקי השירותים:
            </p>
            
            
            
            
            <div>
            <table className='mt-10' style={{tableLayout:'fixed',width:'100%',border:'1px solid grey',borderCollapse:'collapse'}}>
  <thead>
    <tr>
      <th style={{border:'1px solid grey',borderCollapse:'collapse'}}>קטגוריה</th>
      <th style={{border:'1px solid grey',borderCollapse:'collapse'}}>דוגמאות</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td style={{border:'1px solid grey',borderCollapse:'collapse',padding:'10px'}}>פרטים מזהים.</td>
      <td  style={{border:'1px solid grey',borderCollapse:'collapse',padding:'10px'}}>שם אמיתי, כינוי, כתובת דואר, מזהה אישי ייחודי,
מזהים ממשלתיים, מזהה מקוון, כתובת פרוטוקול
אינטרנט (IP), כתובת דואר אלקטרוני, שם חשבון או
מזהים דומים אחרים.</td>
    </tr>
    <tr>
      <td  style={{border:'1px solid grey',borderCollapse:'collapse',padding:'10px'}}>פרטי מיקום.</td>
      <td  style={{border:'1px solid grey',borderCollapse:'collapse',padding:'10px'}}>מיקום GPS של מכשיר נייד, מיקום פיזי או תנועות
(בזמן שהאפליקציה בשימוש).</td>
    </tr>
    <tr>
      <td  style={{border:'1px solid grey',borderCollapse:'collapse',padding:'10px'}}>פרטי חיוב ופרטים פיננסיים.</td>
      <td  style={{border:'1px solid grey',borderCollapse:'collapse',padding:'10px'}}>חשבון בנק ופרטי תשלום, חשבוניות והיסטוריית
עסקאות, טפסי מס וכו&#39;.</td>
    </tr>
    <tr>
        <td  style={{border:'1px solid grey',borderCollapse:'collapse',padding:'10px'}}>פרטי הזמנות, עסקאות
ושירותים.</td>
        <td  style={{border:'1px solid grey',borderCollapse:'collapse',padding:'10px'}}>שירותים מקצועיים המבוצעים על ידי ספקי השירותים,
הזמן המושקע במתן השירותים, פרטי התקשרות,
העדפות משמרת ופרטים נוספים שסופקו על ידי ספקי
השירותים במהלך השימוש שלהם בשירותי
הפלטפורמה.</td>
    </tr>
    <tr>
        <td  style={{border:'1px solid grey',borderCollapse:'collapse',padding:'10px'}}>
        רישומי פעילות.
        </td>
        <td  style={{border:'1px solid grey',borderCollapse:'collapse',padding:'10px'}}>
        קישוריות, נתוני שימוש מצטברים ויומני פעילות, זמן
כניסה ויציאה, מזהה המשתמש, כתובות IP, נתוני
מכשיר והאפליקציה (כמו סוג, מערכת הפעלה, מזהה
מכשיר, גרסת אפליקציה, גרסת דפדפן, אזור זמן
ושפה בשימוש, רישומי פעילות וניתוח הפעלה,
והעוגיות והפיקסלים המותקנים או שבשימוש
במכשיר).
        </td>
    </tr>
    <tr>
        <td  style={{border:'1px solid grey',borderCollapse:'collapse',padding:'10px'}}>
        מידע אישי.
        </td>
        <td  style={{border:'1px solid grey',borderCollapse:'collapse',padding:'10px'}}>
        מאפיינים פיזיים ו\או תיאור פיזי, תמונה, אשרת
עבודה/שהייה, תעודת זיהוי.
        </td>
    </tr>
    <tr>
        <td  style={{border:'1px solid grey',borderCollapse:'collapse',padding:'10px'}}>
        דירוג וציון.
        </td>
        <td  style={{border:'1px solid grey',borderCollapse:'collapse',padding:'10px'}}>
        הדירוג שסופק על ידי לקוח(ות) שהשתמש(ו)
בשירותים המקצועיים של ספק השירותים.
        </td>
    </tr>
  </tbody>
</table>
            </div>
            <p style={{marginTop:'2rem'}}>
            מדיניות פרטיות זו חלה על כל הדרכים והאפשרויות בהן מופעלים שירותי הפלטפורמה
לרבות )אך לא רק) אתר האינטרנט והאפליקציה של החברה, ולכל אחד משירותי
הפלטפורמה הניתנים על ידי החברה.
            </p>
            <p className='underline my-5'>
            1. איסוף המידע.
            </p>
            <p>
            המקורות העיקריים מהם החברה אוספת את המידע הם:
            </p>
            <p>
            1.1 פעילויות המשתמשים בעת השימוש בשירותי הפלטפורמה [למעט שיחות אודיו,
וידאו או שיחות מקוונות בין ספקי השירותים והלקוחות שאינן מפוקחות ואינן
נאספות על ידי החברה].
            </p>
            <p>
            1.2 מידע הנמסר מרצון ובהסכמה על ידי ספקי השירותים לחברה.
            </p>
            <p>
            1.3 מידע הנמסר על ידי צדדים שלישיים, כגון קבלני משנה, אשר נמצאים בשותפות
עם החברה, על מנת לספק את שירותי הפלטפורמה.
            </p>
            <p className='underline my-5'>
            2. שימוש החברה במידע.
            </p>
            <p>
            החברה משתמשת ומעבדת את המידע למטרות הבאות:
            </p>
            <p>
            2.1 על מנת לפתח, לשפר ולספק את שירותי הפלטפורמה.
            </p>
            <p>
            2.2 על מנת לחבר בין הלקוחות ונותני השירותים.
            </p>
            <p>
            2.3 למטרות אבטחה.
            </p>
            <p>
            2.4 על מנת להבטיח ציות לחוק.
            </p>
            <p>
            2.5 במקרה של הליכים משפטיים הנוגעים למשתמשים.
            </p>
            <p>
            2.6 על מנת לאמת את זהות ספקי השירותים.
            </p>
            <p className='underline my-5'>
            3. העברת המידע.
            </p>
            <p>
            החברה תשמור את המידע בסודיות מוחלטת, בהתאם להוראות החוקים והתקנות
להגנת המידע החלים. החברה רשאית להעביר או לשתף את המידע, או חלקים מהמידע
בנסיבות הבאות:</p>
            <p>3.1 בהסכמת נשוא המידע.</p>
            <p>3.2 במידה הנדרשת לתפעול שירותי הפלטפורמה. לדוגמה, שיתוף מידע מוגבל (כגון</p>
<p>שם, תמונה, תעודות זיהוי, אשרות וקרבה גיאוגרפית ללקוח) עם לקוחות, על מנת</p>
<p>ליצור התאמה בין הלקוחות לבין ספקי השירותים.</p>
<p>3.3 כדי לעמוד בדרישות החוק.</p>
<p>3.4 לסייע במניעה או חקירה של פשע.</p>
<p>3.5 כדי להגן על בטיחותו של אדם אחר.</p>
<p>3.6 לאכוף את הזכויות המשפטיות של החברה.</p>
<p>3.7 במקרה של מיזוג, מכירה, רכישה, ארגון מחדש, פירוק או כל דרך אחרת של שינוי</p>
<p>שליטה בחברה. עם זאת, במקרים בהם בכוונת הישות הרוכשת או הממזגת את</p>
<p>החברה להשתמש במידע באופן שאינו עולה בקנה אחד באופן מהותי עם</p>
<p>ההוראות לעיל, החברה תמסור לספקי השירותים הודעה מוקדמת בכתב, על מנת</p>
<p>לאפשר להם לבטל את הסכמתם לשימוש במידע.</p>
<p className='underline my-5'>4. זכויות ספקי השירותים.</p>
<p>4.1 בכפוף ובהתאם לכל חוקי ותקנות הגנת המידע החלים, ספקי השירותים רשאים</p>
<p>להגיש בקשה לעיין, לעדכן ו/או למחוק את המידע הקשור אליהם אשר נאסף על</p>
<p>ידי החברה. בקשה כזו תישלח על ידי ספק השירותים הרלוונטי (ייתכן ותידרש</p>
<p>הוכחת זהות), לכתובת הדוא&quot;ל הבאה: <a data-fr-linked="true" href="mailto:_service@wearetech.me">_service@wearetech.me</a> (להלן: &quot;בקשה</p>
<p>לקבלת מידע&quot;).</p>
<p>4.2 החברה לא תידרש לזהות מחדש או לקשר לספק שירותים מידע שאינו נחשב</p>
<p>למידע אישי.</p>
<p>4.3 במקרה שספק שירותים סבור שהמידע הקשור אליו אינו מדויק, חלקי או אינו</p>
<p>מעודכן, הוא רשאי לשלוח בקשה לעדכון המידע.</p>
<p>4.4 במקרה שבו בקשה לקבלת מידע קשורה גם למידע הקשור ללקוחות, כגון הודעה</p>
<p>או שיחה שהתקבלה מלקוח מסוים באמצעות שירותי הפלטפורמה, אזי על הלקוח</p>
<p>הרלוונטי להיות צד לבקשה כאמור על מנת שהחברה תקבלה.</p>
<p>4.5 החברה תעשה את מירב המאמצים להגיב, לעיין, לעדכן ו/או למחוק את הבקשות</p>
<p>האמורות מוקדם ככל האפשר, ובכל מקרה לא מאוחר מ-45 ימים מיום קבלת</p>
<p>בקשה כאמור. במידה שנדרשת תקופה ארוכה יותר, בשל אופייה והיקפה של</p>
<p>בקשה כזו, החברה רשאית להאריך תקופה זו ב-45 ימים נוספים, על ידי מתן</p>
<p>הודעה בכתב לספק השירותים הרלוונטי.</p>
<p>4.6 החברה תספק את המידע בפורמט נייד המאפשר העברת מידע זה.</p>
<p>4.7 מידע כזה עשוי לכלול:</p>
<p>4.7.1 קטגוריות המידע שהחברה אספה לגבי ספק השירותים.</p>
<p>4.7.2 קטגוריות המקורות למידע שהחברה אספה לגבי ספק השירותים.</p>
<p>4.7.3 המידע הספציפי שהחברה אספה לגבי ספק השירותים.</p>
<p>4.8 מבלי לגרוע מזכויות החברה על פי כל חוק ו/או תקנות החלים, החברה רשאית</p>
<p>לדחות בקשות מחיקה, כאשר שמירת המידע כאמור נדרשת על מנת: לבצע את</p>
<p>התחייבויות החברה כלפי הלקוחות ו/או נותני השירותים; לזהות אירועים</p>
<p>ביטחוניים; לזהות ולנפות שגיאות מערכת; לאכוף את הזכויות המשפטיות ו/או</p>
<p>החוזיות של החברה; להגן מפני פעילות בלתי חוקית או להעמיד לדין את</p>
<p>האחראים לפעילות כזו, או כאשר החשיפה, העדכון ו/או המחיקה של מידע זה</p>
<p>אסורים על פי כל חוק.</p>
<p>4.9 שום ספק שירות לא יופלה לרעה בקשר עם הגשת בקשות מסוג זה.</p>
<p className='underline my-5'>5. תקופת אחסון.</p>
<p>החברה אינה מאחסנת את הנתונים האישיים של ספקי השירותים זמן רב יותר ממה</p>
<p>שמותר על פי דין והכרחי למטרות אספקת שירותי הפלטפורמה או החלקים הרלוונטיים</p>
<p>מהם. תקופת האחסון תלויה באופי המידע ובמטרות העיבוד. לכן התקופה המקסימלית</p>
<p>עשויה להשתנות לפי שימוש.</p>
<p>מכיוון שמתן שירותי הפלטפורמה וביצוע השירותים המקצועיים כפופים להתחייבויות</p>
<p>משפטיות וחוזיות מסוימות, רוב המידע האישי הנוגע לשימוש של משתמשים בחשבונות</p>
<p>שלהם, יימחק ברגע ששימוש כזה לא יהיה כפוף עוד לכל הליך משפטי או להיות נחוץ</p>
<p>באופן סביר להתחייבויות המשפטיות או האינטרסים הלגיטימיים של החברה, כגון טיפול</p>
<p>בתביעות, הנהלת חשבונות, דיווח פנימי, והתאמת ספרים. כל הנתונים האישיים</p>
<p>הקשורים לחשבון של ספק השירותים יימחקו תוך תקופה של 10 שנים לאחר שספק</p>
<p>השירותים מחק את חשבון המשתמש שלו, למעט נתונים אישיים הנדרשים במצבים</p>
<p>נדירים מסוימים כגון הליכים משפטיים.</p>
<p className='underline my-5'>6. מדיניות עוגיות (COOKIES).</p>
<p>6.1 החברה משתמשת בעוגיות ובטכנולוגיות דומות על מנת לזהות משתמשים</p>
<p>ומכשיריהם, לאסוף נתונים סטטיסטיים, למטרות אבטחה ועל מנת לספק שירותי</p>
<p>פלטפורמה מאובטחים ומשופרים, לרבות (אך לא רק), שמירת העדפות</p>
<p>המשתמש, התאמה אישית של תוכן ופרסום ממוקד.</p>
<p>6.2 שימוש בשירותי הפלטפורמה של החברה, לרבות (אך לא רק) ביקור באתר</p>
<p>ובאפליקציה של החברה, ייחשב כהסכמת המשתמש לאיסוף מידע למטרות</p>
<p>המוזכרות במדיניות העוגיות של החברה (סעיף 6 זה).</p>
<p>6.3 דפדפני אינטרנט מסוימים מאפשרים למשתמשים שלהם לחסום קובצי עוגיות.</p>
<p>הוראות כיצד לחסום קובצי עוגיות ניתן למצוא בחלק העזרה של הדפדפן שלך.</p>
<p>שימוש באפשרויות כאלה עלול להשפיע לרעה על יכולתה של החברה לספק את</p>
<p>שירותי הפלטפורמה.</p>
<p className='underline my-5'>7. הגנה על מידע.</p>
<p>החברה שומרת את המידע בסודיות מוחלטת, ובהתאם לדרישות החוקים והתקנות</p>
<p>להגנת המידע הרלוונטיים לכל משתמש.</p>
<p className='underline my-5'>8. פרטיות ילדים.</p>
<p>השירותים מוגבלים למשתמשים בני 18 ומעלה. החברה אינה מתירה למשתמשים</p>
<p>מתחת לגיל 18 להשתמש בשירותי הפלטפורמה בכל צורה או אופן ואינה אוספת מידע</p>
<p>ביודעין מאף אדם מתחת לגיל 18.</p>
<p className='underline my-5'>9. תושבי כל תחום שיפוט מלבד ישראל.</p>
<p>נכון לעכשיו, החברה אינה מציעה ו/או מספקת את שירותי הפלטפורמה שלה מחוץ</p>
<p>לישראל. אם אינך תושב ישראל, הנך מתבקש בזאת שלא להשתמש בשירותים בשום</p>
<p>צורה או אופן ואינך חוסה תחת תנאי מדיניות פרטיות זו.</p>
<p className='underline my-5'>10. תיקונים למדיניות הפרטיות של החברה.</p>
<p>החברה רשאית לשנות מדיניות פרטיות זו מעת לעת. הגרסה המעודכנת של מדיניות</p>
<p>הפרטיות תהיה זמינה למשתמשים באתר החברה ובאפליקציה. כל שינוי מהותי</p>
<p>במדיניות הפרטיות של החברה יפורסם, בין באתר האינטרנט של החברה ובין בכל דרך</p>
<p>אחרת, כפי שהחברה תמצא לנכון.</p>

            </div>
        </div>
    )
}
