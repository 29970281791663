import { useEffect, useState } from "react";


export default function OurAdvantagesItem(props) {
    
    const [moveSpeedIcon,setMoveSpeedIcon] = useState('');

    useEffect(()=>{
        if(props.id == 3)
        {
            setMoveSpeedIcon('pr-28')
        }
        else{
          setMoveSpeedIcon('');
        }

    },[]);

    return (
        <div className="w-80 mb-24 sm:mb-16">
        
        <div>
        <img className={`mx-auto sm:mb-4 sm:h-28 ${moveSpeedIcon}`} src ={props.icon}></img>
        </div>
        <div className="sm:mb-3 mt-4 sm:h-[50px] " >
            <span className="font-Heebo text-lg  font-medium sm:text-2xl" style={{color:'#FA8416'}}>
                {props.title}
            </span>
        </div>
        <div className="mx-auto text-white px-6">
          <span style={{color:'#1F1546'}}>{props.text}</span>
        </div>
      </div>
    );
  }