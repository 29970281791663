import React, { useEffect, useState} from 'react';
import Modal from './Modal';
import {createRestaurant,statusCode} from '../utils/fetcher'
import {Formik,Field,Form, ErrorMessage} from 'formik'
import Map from './Map';
import { Outlet, useNavigate,useLocation } from 'react-router-dom';
import start2wewash from '../images/Start2WeWash.png'
import { checkPhoneField,checkIfHaveSpaces,checkIfOnlyNumbers,emailChecker, checkIfNospaces, checkForSpecialSymbols } from '../utils/checkFields';
import { Button, Spinner } from 'reactstrap';
import UploadModal from './UploadModal';
import CropImage from './CropImage';
import { CropOriginal } from './CropOriginal/CropOriginal';
import { ClipLoader } from 'react-spinners';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';





export const BusinessLogoContext = React.createContext();

export default function FormRest() {

  const navigate = useNavigate();
  const {state} = useLocation();
  const [t,i18n] =useTranslation("global");

  const [termsCheckBox,setTermsCheckBox] = useState(false);
  const [businessLogo,setBusinessLogo] = useState('');
  const [hideRegisterPart,setHideRegisterPart] = useState(false)
  const [spinner,setSpinner] = useState(false);

  const recapchaKey = process.env.REACT_APP_RECAPTCHAKEY;
  const [capVal,setCapVal] = useState(null);




  const [formErrors, setFormErrors] = useState({});
  const [restAddress,setRestAddress] = useState('');
  //const phoneNumberRegex = '[0][0-9]{9}';
  const [modal,setModal] = useState(false);
  const [addressInput,setAddressInput] = useState(true);
  const [imageSimpleInput,setImageSimpleInput] = useState();
  const [statusCode, setStatusCode] = useState(null);

  useEffect(()=>{
    if(state)
    {
    setRestAddress(state.address?.formatted_address);
    }

  },[state])

  function NavigateToHome(){
    navigate('/');
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' })

  }
  const handleBusinessLogoChange = (value) => {
    setBusinessLogo(value);
  };

  function CloseModalHandler(){
    setModal(false);
  }


  const toggleModal = () => {
    setModal(true);
  };

    return (
      <div>
      <div className='formik mt-[60px]'>
      <Formik
      initialValues={{
      firstName: '',
      lastName:'',
      name:'',
      address:'',
      email: '',
      businessNumber:'',
      contactPersonPhone:'',
      businessLeadLogo:[],
      isLead:false
        }}
         validate={(values) => {
           const errors = {};
           if (!checkIfNospaces.test(values.name)) {
             errors.name = t("Errors.RequiredField");
           }
            if (!checkIfNospaces.test(values.firstName) || checkForSpecialSymbols.test(values.firstName)) {
                 errors.firstName =t("Errors.RequiredField");
          }
          if (!(values.email.match(emailChecker))) {
            errors.email =  t("Errors.RequiredField");
          }

          if (!checkIfNospaces.test(values.lastName) || checkForSpecialSymbols.test(values.lastName)) {
            errors.lastName = t("Errors.RequiredField");
          }
          if (!values.businessNumber.match(checkIfOnlyNumbers)) {
            errors.businessNumber =t("Errors.RequiredField");
          }
          // if (!values.address ) {
          //   errors.address = "שדה חובה";
          // }

          if (!values.contactPersonPhone.match(checkPhoneField)) {
            errors.contactPersonPhone = t("Errors.PhoneNumberError");
          }
          setFormErrors(errors);
           return errors;
         }}

         onSubmit={ async (formData) => {
          if(state.lead)
          {
           formData.isLead = state.lead;
          }
          formData.address = state.address;
          formData.businessLeadLogo = imageSimpleInput;
          setSpinner(true)

         
       //  const getData = async () => {
            await createRestaurant(formData)
            .then((res)=>{
             // console.log('result',res)
             if(res?.status == '200'){
              setSpinner(false)
              setModal(true)
             }
             else 
             {
              let errorsText = [];
               res.map((error)=>{
                errorsText.push(error.message + '\n')
              })
              errorsText.push(t("CustomersForm.ErrorsLastLine"));
              alert(errorsText.join('\n'));
              setSpinner(false)
             }
             
            })
            .catch((error)=>{
             alert(t("Errors.SomethingHappend"))
             setSpinner(false);
             setImageSimpleInput('');
            })
            
         // };
        //  getData();
         //
        }}


      >

      <Form>
        <div className="w-full max-w-6xl mx-auto mb-10 sm:mb-32">
          {modal && 
          <Modal  
          text={state?.lead || 
          process.env.REACT_APP_SHOULDREGISTERBUSINESS === "false" ? t("Modal.WeWillContactYou") 
          : 
          t("Modal.ModalText")} 
          btnText={t("Modal.BtnToEnd")} 
          
          RoutinActions = 'ToStart' closeModalHandler  = {CloseModalHandler} goToLink='/' />}

        <div className="sm:bg-white sm:shadow-md rounded-3xl px-10 pb-10">
            <div className='py-8'> <h1 className='text-2xl sm:text-5xl font-Heebo' style={{color:'#1f1546'}}>
              {t("CustomersForm.ContinueTo")}</h1></div>
            <div className='py-6'> <h1 className='text-2xl sm:text-2xl font-Heebo' style={{color:'#1f1546'}}
            >
              {t("CustomersForm.ImportantMessage")}
            </h1></div>
          <div className="sm:flex" >
           <div className="inline w-full sm:ml-5 ">
           <Field className="w-full mb-4 sm:mb-8 rounded-full appearance-none border formBorderFields  py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
           type="text"
           name='firstName'
           maxLength={50}
           placeholder={t("CustomersForm.FirstName")}/>
           <ErrorMessage name="firstName" component="div" className='text-red-600 -mt-[20px] sm:-mt-[35px] mr-4 sm:ml-4 sm:mb-4 mt:-4 mb:4' />

           </div>
           <div className="inline w-full ">
           <Field className="w-full mb-4 sm:mb-8 mt-4 sm:mt-0   rounded-full appearance-none border formBorderFields py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
           name='lastName'
           maxLength={50}
           type="text" placeholder={t("CustomersForm.LastName")}/>
             <ErrorMessage name="lastName" component="div" className='text-red-600 -mt-[20px] sm:-mt-[35px] mr-4 sm:ml-4 sm:mb-4 mt:-4 mb:4' />

           </div>
            <span>
            </span>

          </div>
          <div className="sm:flex">
          <div className="inline w-full sm:ml-5 ">
          <Field className="w-full mb-4 sm:mb-8 mt-4 sm:mt-0  rounded-full appearance-none border formBorderFields py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          name ='name'
          maxLength={100}
          type="text" placeholder={t("CustomersForm.BusinessName")}/>
           <ErrorMessage name="name" component="div" className='text-red-600 -mt-[20px] sm:-mt-[35px] mr-4 sm:ml-4 sm:mb-4 mt:-4 mb:4' />
           </div>
           <div className="inline w-full ">
           <Field className="w-full mb-4 sm:mb-8 mt-4 sm:mt-0   rounded-full appearance-none border formBorderFields py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
           name='email'
           maxLength={50}
           type="text" placeholder={t("CustomersForm.email")}/>
             <ErrorMessage name="email" component="div" className='text-red-600 -mt-[20px] sm:-mt-[35px] mr-4 sm:ml-4 sm:mb-4 mt:-4 mb:4' />

           </div>

          </div>
          <div className="w-full">
          <Field className="w-full mb-4 sm:mb-8 mt-4 sm:mt-0  rounded-full appearance-none border formBorderFields py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
           name = 'businessNumber'
           maxLength={30}
           type="text" placeholder={t("CustomersForm.ltdNumber")}/>
            <ErrorMessage name="businessNumber" component="div" className='text-red-600 -mt-[20px] sm:-mt-[35px] mr-4 sm:ml-4 sm:mb-4 mt:-4 mb:4' />
          </div>
          <div className="w-full ">
          <Field dir='ltr' className="w-full mb-4 sm:mb-8 mt-4 sm:mt-0 text-right  rounded-full appearance-none border formBorderFields py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name = 'contactPersonPhone'
          type="text" placeholder={t("CustomersForm.PhoneNumber")}/>
           <ErrorMessage name="contactPersonPhone" component="div" className='text-red-600 -mt-[20px] sm:-mt-[35px] mr-4 sm:ml-4 sm:mb-4 mt:-4 mb:4' />
          </div>
          <div className="w-full  ">
          <Field className="w-full mb-4 sm:mb-8 mt-4 sm:mt-0  rounded-full appearance-none  formBorderFields py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
           name ='address'
           type="text"
           value = {restAddress}
           disabled = {addressInput}
           placeholder={t("CustomersForm.BusinessAddress")}/>
           <ErrorMessage name="address" component="div" className='text-red-600 -mt-[20px] -mt-[20px] sm:-mt-[35px] sm:mb-4 mr-4 sm:ml-4 mt:-4 mb:4' />
          </div>
         
          <div className='w-fit max-w-[300px]' style={{backgroundColor:'black'}}>
            <img src={businessLogo?.preview}/>
           

          </div>
          {/* <div>
          <input type="file" onChange={(e)=>{setImageSimpleInput(e.target.files[0])}} id="myfile" name="myfile"/>
          </div> */}
          <BusinessLogoContext.Provider value={setBusinessLogo}>
          <div>
              {/* <UploadModal
          type ='image'
          aspectText = '1 x 1'
          aspect = {1 / 1}
          limit = {1}
          buttonLabel = 'Select logo'
         // providedImages = {[]}
          
          /> */}
          </div>
        </BusinessLogoContext.Provider>
           {/* <div className='mb-3 mt-3'>
                <Button
                  type="button"
                  color="primary">
                  Click Me
                </Button>
              </div> */}
              <div>
                <CropOriginal setImageSimpleInput = {setImageSimpleInput} setHideRegisterPart={setHideRegisterPart}/>
              </div>
          {hideRegisterPart  &&  
          <div>
          <div className='flex justify-start sm:mb-4 mb-4 mr-2 content-center'>
            <div>
          <input style={{width:'20px',height:'20px'}} type="checkbox" onChange = {() => setTermsCheckBox(!termsCheckBox)} />
          </div>
          <div><span className='mr-2'>{t("CustomersForm.AcceptTerms")} <span className='cursor-pointer' 
          onClick={()=> window.open('/TermsOfUseStart2', '_blank', 'noopener,noreferrer')} 
          style={{color:'blue'}}>{t("CustomersForm.Term")}</span> {t("CustomersForm.Site")}</span></div>
          </div>
          <div className="w-full mt-4 sm:mt-0">
            <div className='flex mb-4'>
              <div>
                  <ReCAPTCHA sitekey={recapchaKey} onChange={(val) => setCapVal(val)}/>
              </div>
             
            </div>
         
          <button
          type='submit'
          disabled = {termsCheckBox && capVal ? false : true || spinner ? true : false}
          className="py-4 mb-4 sm:mb-8 w-full hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full buttonText"
          style={{backgroundColor:'#1F1546',opacity:termsCheckBox  && capVal ? '1' : '0.5'}}>
             <span className={spinner ? 'hidden' : ''}>
              {t("CustomersForm.Join")}
             </span>

             <span className={spinner ? '' : 'hidden'}><ClipLoader size='20px' color='white'/></span>
         </button>
          </div>
          </div>}
        </div>
      </div>
      </Form>
      </Formik>


      </div>
      </div>
    );
  }