import './App.css';
import React, { useEffect, useState,useRef } from 'react';
import Form from './components/Form';
import Map from './components/Map';
import OurAdvantages from './components/OurAdvantages';
import Hero from './components/Hero';
import { Outlet} from 'react-router-dom';
import EmbeddedVideo from './components/EmbeddedVideo';
import Card from './components/Card';
import RecruitmentDaySelection from './components/RecruitmentDaySelection';
import FormRest from './components/Form';
import LanguagesBtns from './components/LanguagesBtns';
import EmplyeesForm from './components/EmployeesForm';
import { getLocationPath, getPath } from './utils/pathChecker';
import {useTranslation} from "react-i18next"
import WeWash from './components/WeWash';
import Testimonials from './components/Testimonials';
import ImagesSlider from './components/ImagesSlider';


function App() {
  const formRef = useRef(null);
  const [t,i18n] =useTranslation("global");

  const [hidePart,setHidePart] = useState(true)
  useEffect(()=>{
  const path =  getLocationPath();
    
    if(path == '/customers')
  {
    setHidePart(true)
  }
  if(path == '/employes'){
    setHidePart(false)

  }
 

  },[])

  return (
    
    <section className="App mb-40">
      <Outlet/>
       <section>
       <div className=' sm:mb-8'> <section className='aboutUs max-w-[1365px] m-auto'>
      {hidePart ?
      <EmbeddedVideo title="Start2wash סרטון מסעדנים" 
      link = 'https://www.youtube.com/embed/fYEoOpoi2xw?si=D8yxbLaUxGFDV9nh'/>:
      <div>
        {i18n.language ==='tig'?
         <EmbeddedVideo title="Start2wash סרטון גיוס עובדים" 
         link = 'https://www.youtube.com/embed/yL_wokQQqrc?si=OtHpf_MSlfEdBQsS'/> :
         
         <EmbeddedVideo title="Start2wash סרטון גיוס עובדים" 
         link = 'https://www.youtube.com/embed/N5YBaiqTtBo?si=ORNN-nAtxfut2Ay0'/>

        }
        

       
        <OurAdvantages/>
      </div>
      
      
      }

    </section>
       <section className='ourAdvantages'>
        {hidePart && 
      <div className='max-w-[1365px] m-auto' >
        
        <OurAdvantages/>
        <WeWash/>
      </div>}
    </section>

  
 </div>
   
    
    <section className=''>
      <div className='' style={{textAlign:'-webkit-center'}} >
      {hidePart &&
      <div className=' mx-4 max-w-[950px]'>
       
      <div className='flex justify-center nameOfService text-lg sm:text-5xl sm:mb-6 mb-2 sm:mt-[100px]'>
      <span  id='map'>
        
       {t("MapHeaderText")}
      </span>
    </div>
    <div className='flex justify-start nameOfService text-lg sm:text-3xl sm:mb-6 mb-2 sm:mt-[5px]'>
    <span  id='map'>
       {t("MapHeaderSubText")}
       
        {t("MapHeaderSubText2")}
       <br/> <br/>
       {t("MapHeaderSubSubText")}
       <br/>
       {t("MapHeaderSubSubText2")}
       <br/><br/>
       {t("MapHeaderSubSubText3")}
       <u><a href='https://wa.me/972503031037' target='_blank'>050-3031037</a></u>
       <br/>
       {t("MapHeaderSubSubText4")}
       <br/>
       {t("MapHeaderSubSubText5")}
       <br/>
       {t("MapHeaderSubSubText6")}
       <br/>
       {t("MapHeaderSubSubText7")}
       <br/><br/>
       {t("MapHeaderSubSubText8")}
       <br/>
       {t("MapHeaderSubSubText9")}
       <br/><br/>
       {t("MapHeaderSubSubText10")}
       <br/><br/>
     </span>
    </div>
    
        
        
          <div>
          <Map/>
          </div>
          <div className='mt-40 p-8' style={{border:'1px solid',borderColor:'1f1546',borderRadius:'30px'}}>
            <Testimonials/>
          </div>
        </div>
      }
      </div>
    </section>
    {hidePart  && <div>
        <Card/>

  </div>}
    <section >
    {/* {!hidePart && 
    <RecruitmentDaySelection/>} */}
    {!hidePart &&
    <div id='employesForm'>
    <EmplyeesForm/>
    </div>}
    </section>

   {!hidePart && <div className='flex justify-center '>
     <div className='mx-4 max-w-[800px]'>
      <div className='font-Heebo text-center mt-10 sm:mt-0 mb-6 text-xl font-medium sm:text-4xl sm:font-medium cursor-pointer'
      style={{color:'#1f1546'}}
      >
        <a href='https://maps.app.goo.gl/K7rr1tJDEFngtrWq5' target='_blank'>
        <span>
        {t("OurOffices")}
        </span>
        </a>
        </div>
      <ImagesSlider/>
      </div> 
    </div>}
      </section>
    </section>
  );
}

export default App;
