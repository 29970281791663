import React, { useState, createRef } from "react";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import "./Demo.css";
import restaurantImage from '../../images/RestLogoTemplate.jpg'
import imageCompression from 'browser-image-compression';
import { useTranslation } from 'react-i18next';
import { ClipLoader } from 'react-spinners';


const defaultSrc =
  restaurantImage;

export const CropOriginal =  ({setImageSimpleInput,setHideRegisterPart}) => {
  const [image, setImage] = useState(defaultSrc);
  const [cropData, setCropData] = useState(defaultSrc);
  //const [uploadBtn,setUploadBtn] = useState(true);
  const [initImage,setInitImage] = useState(restaurantImage);
  const [hideCropperPart,setHideCropperPart] = useState(false)
  const [t,i18n] =useTranslation("global");
  const [spinner,setSpinner] = useState(false)


  const cropperRef = React.createRef();
  const onChange = (e) => {
    setInitImage(e.target.files[0]?.name);
   // setUploadBtn(false);
    setHideCropperPart(true);
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
   if(files[0]){
    reader.readAsDataURL(files[0]);
  }
   
  };

  const getCropData = () => {
    setSpinner(true);
   setTimeout(() => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
      setSpinner(false)
      // -----
      const imageElement = cropperRef?.current;
      const cropper = imageElement?.cropper;
      cropper.getCroppedCanvas().toBlob(async (blob) => {
        // crashes without this check, do not remove
        if (Object.prototype.toString.call(blob) === '[object Blob]') {
          const compressedFile = await imageCompression(blob, {
            maxSizeMB: 0.5,
            maxWidthOrHeight: 700,
          });
          let mutatedCompressFile = new File([compressedFile],initImage, {
            type: blob.type,
          });
          setImageSimpleInput(mutatedCompressFile);
          setHideRegisterPart(true);
         /// console.log(mutatedCompressFile)
        }
      });

      //----
    }
    }, 1000);
  };

  return (
    <div>
      <div className="mt-6">
        <h1 className="text-xl sm:text-2xl" style={{color:'#1f1546'}}>
          {t("Cropper.AlmostDone")}
        </h1>
       <div className="flex justify-center"> 
          <div className="mt-4 upload-btn-wrapper pt-4 pb-1 px-8">
          <button className="btn rounded-full" style={{backgroundColor:'#1f1546',color:'white'}}>
            <span className="buttonText">
            {t("Cropper.UploadLogo")}
            </span>
          </button>
           <input type="file" onChange={onChange} accept="*,.jpg,.jpeg,.png"/>
        </div>
        </div> 
        <div>
              <div style={{color:'black'}}>{t("Cropper.SupportedFiles")}</div>
             </div>
      </div>

      {/* cropper part */}
      {hideCropperPart &&<div className="mt-3">
       
        <div style={{ width: "100%" }}>
     
       
        <Cropper
          ref={cropperRef}
          // className="w-250 h-36"
          style={{width:250,height:250}}
          aspectRatio={1 / 1}
          zoomTo={0}
          initialAspectRatio={1}
          preview=".img-preview"
          src={image}
          viewMode={1}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={1}
          checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
          guides={true}
        />
      </div>
      <div className="max-w-50 buttonWrapper"> 
      <div className="mt-4 btn-wrapper ">
          <button 
          type="button" 
          className="btn rounded-full" 
          style={{backgroundColor:'#1f1546',color:'white'}} 
          onClick={getCropData}>
            
             <span className={spinner ? 'hidden' : ''}>
             {t("Cropper.FitPicture")}
             </span>
             <span 
             className={spinner ? '' : 'hidden'}>
              <ClipLoader size='20px' color='white'/>
              </span>

            
            </button>
          </div>
           
        <div>
          <h1 className="my-4 text-xl sm:text-xl">
            <span>
            {t("Cropper.FittedPicture")}
              </span>
          </h1>
         
          <div className="mb-8 w-36 h-36">
             <img src={cropData} alt="cropped" />
          </div>
         
        </div>
      </div>
      </div>}
    </div>
  );
};

export default CropOriginal;
