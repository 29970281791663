import React, { useEffect, useState, useTransition } from "react";
import '../Modal.css'
import closeIcon from '../images/closeIcon.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';
import LinksToApps from "./LinksToApps";



export default function Modal({ text, btnText, closeModalHandler,RoutinActions, goToLink, addressObj, hideSearchFieldAndBtn, hideForm }) {
  const [t, i18n] = useTranslation("global");
  const [modal, setModal] = useState(true);
  const [links, setLinks] = useState(false)
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useLocation();



  function NavigateToHome() {

    if (RoutinActions === 'JoinAnyway') {
      navigate('/customers', { state: { address: addressObj, lead: true } });
      hideForm(false)
      hideSearchFieldAndBtn(false)
      scrollToForm();
    }
    if (RoutinActions === 'ToStart') {
      navigate('/')
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    if(btnText === t("BtnText.Thanks")){
     
      //navigate('/');
    }
    if (RoutinActions == 'JoinNow') {
      navigate('/customers', { state: { address: addressObj, lead: false } });
      hideForm(false)
      hideSearchFieldAndBtn(false)
      scrollToForm();
    }

    CloseModal();
    //CloseModalCross();
  }


  useEffect(() => {
    if (goToLink == '/') {
      if (state?.lead) {

      }
      else {
        if(process.env.REACT_APP_SHOULDREGISTERBUSINESS === "true")
        {
           setLinks(true);
        }
      }
    }
  }, [goToLink])



  function scrollToForm() {
    const element = document.getElementById('formRef');
    element.scrollIntoView({ behavior: 'smooth' });

  }

  function CloseModal() {
    RemoveClassActiveModal();
    closeModalHandler();
  }





  if (modal) {
    document.body.classList.add('active-modal')
  } else {
    RemoveClassActiveModal();
  }

  function RemoveClassActiveModal() {
    document.body.classList.remove('active-modal')
  }




  return (
    <>

      <div className="modal">
        <div className="overlay"></div>

        <div className="modal-content rounded-3xl">
          <div className="text-center">
            <img
              onClick={CloseModal}
              className="pr-1 mt-1 cursor-pointer" src={closeIcon} alt="close icon" />
          </div>

          <div className="flex justify-center vacanciesText px-4 mb-4" >
            <div>
              <p className="text-xl sm:text-2xl">
                {text}
              </p>
              {links && <div>
                <LinksToApps />
              </div>}
              <div className="flex justify-center">
                <div>
                   <button
                onClick={NavigateToHome}
                className="mt-6 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-full buttonText" style={{ backgroundColor: '#1F1546' }}>
                {btnText}
              </button>
                </div>
                
              </div>
             
            </div>

          </div>

        </div>
      </div>

    </>
  );
}