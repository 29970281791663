import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import {useLocation } from 'react-router-dom';
import { getLocationPath } from "../utils/pathChecker";

export default function EmbeddedVideo(props) {
    const [t,i18n] =useTranslation("global");
    const [title,setTitle] = useState(t("VideoTitles.RestaurantOwnersVideo"))
    const location = useLocation();
    const path = getLocationPath();
  

  useEffect(()=>{
    
    if(path == '/customers')
     {
       setTitle(t("VideoTitles.RestaurantOwnersVideo"))
     }
      if(path == '/employes'){

        setTitle(t("VideoTitles.RecuirementVideo"))
     }
     if(path == '/article'){

      setTitle(t("VideoTitles.RecuirementVideo"))
   }
 

  },[t])
    
    return (
        <div className='mx-4'>
        <div className='flex justify-start nameOfService text-lg sm:mb-6 mb-2 sm:text-5xl sm:mt-[150px] mt-[100px]'>
        <span>
            {title}
        </span>
      </div>
      <div className='max-w-[1360px]'>
        <div>
        {/* <img src={video} alt=""/> */}
        <iframe src={props.link} className='w-full aspect-video' style={{borderRadius:'24px'}} allow="autoplay"></iframe>
        </div>

      </div>
      </div>
    );
  }