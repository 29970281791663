import VacancyItem from "./VacancyItem";
import vacanciesItemsData from "../vacanciesData/vacanciesData";
import { useTranslation } from "react-i18next";

export default function Vacancies() {
  const [t,i18n] = useTranslation("global")
    
    return (
      <section className="vacancies">
        <div className="nameOfService text-lg sm:mb-4 mb-4 sm:mt-[80px] mt-[100px]">
         <span className="text-lg sm:text-3xl">
          {t("Vacancies")}
         </span>
        </div>
        <div className="flex flex-wrap justify-between max-w-2xl text-center">
            {vacanciesItemsData.map(item=>{
                return <VacancyItem key ={item.key} {...item}/>
            })}
        </div>
      </section>
    );
  }