import React, { useState } from 'react';
import PlayeStoreLogo from '../images/GooglePlay.png'
import AppstoreLogo from '../images/AppStore.png'

// Define a functional component named SimpleComponent
const LinksToApps = ({lead}) => {
    



  
        return  (
        <div className='p-4'>
        <div className='my-3'>
            <div>
                 <h2 className="text-xl sm:text-2xl mb-3 font-Heebo" style={{color:'#1f1546'}}>להורדת האפליקציה לאנדרואיד:</h2>
                 <a href='https://apps.apple.com/us/app/start2-customers/id6444702814' target='_blank'><img src={AppstoreLogo} alt='app store logo'/></a> 
            </div>
           
        </div>
        <div className='my-3'>
        <h2 className="text-xl sm:text-2xl mb-3 font-Heebo" style={{color:'#1f1546'}}>להורדת האפליקציה לאייפון:</h2>
          <a href='https://play.google.com/store/apps/details?id=com.wearetech.wewashbusiness' target='_blank'> <img src={PlayeStoreLogo} alt='play store logo'/></a> 
        </div>
        <div className='mt-6'>
            <h2 className="text-xl sm:text-xl font-Heebo" style={{color:'#1f1546'}}>
                *
            יש להכנס עם מספר הטלפון
            *
            </h2>     
                הזמנת עובדים למשמרות החל מתאריך 
                15/04/24
                
               
        </div>
      
    </div>)
    
};

export default LinksToApps;
