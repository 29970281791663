import { useEffect, useState } from 'react';
import Modal from './Modal';
import {createRestaurant,statusCode, unsubscribe} from '../utils/fetcher'
import {Formik,Field,Form, ErrorMessage} from 'formik'
import { Outlet, useNavigate,useLocation } from 'react-router-dom';
import { checkPhoneField,checkIfHaveSpaces,checkIfOnlyNumbers,emailChecker, checkIfNospaces, checkForSpecialSymbols } from '../utils/checkFields';
import { Logo } from './Logo';

export default function UserDeletionformEmployee() {
  const navigate = useNavigate();
  const {state} = useLocation();
  const [termsCheckBox,setTermsCheckBox] = useState(false);


  const [formErrors, setFormErrors] = useState({});
  const [restAddress,setRestAddress] = useState('');
  //const phoneNumberRegex = '[0][0-9]{9}';
  const [modal,setModal] = useState(false);
  const [addressInput,setAddressInput] = useState(true);

  useEffect(()=>{
    if(state)
    {
      setRestAddress(state.address);
    }

  },[state])

  function NavigateToHome(){
    navigate('/');
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' })

  }

  function CloseModalHandler(){
    setModal(false);
  }


  const toggleModal = () => {
    setModal(true);
  };

    return (
      <div>
        
      <div className='formik pt-[60px]'>
        <div className='flex justify-center'>
          <div>
             <Logo/>
          </div>
       
        </div>
      <Formik
      initialValues={{
      firstName: '',
      email: '',
      contactPersonPhone:'',
      reason:'',
      deleteForUser:'user'
     }}
         validate={(values) => {
           const errors = {};
           if (!checkIfNospaces.test(values.name)) {
             errors.name = "required field";
           }
           if (!values.reason) {
            errors.reason = "required field";
          }
            if (!checkIfNospaces.test(values.firstName) || checkForSpecialSymbols.test(values.firstName)) {
                 errors.firstName = "required field";
          }
          if (!(values.email.match(emailChecker))) {
            errors.email =  "required field";
          }

          

          if (!values.contactPersonPhone) {
            errors.contactPersonPhone = "required field";
          }
          setFormErrors(errors);
           return errors;
         }}

         onSubmit={ async (formData) => {
          await unsubscribe(formData);
          if(statusCode == 200){
            alert('Your Request for Data Deletion from our servers has been registered, We will contact you soon.')
            NavigateToHome();

          }
          else {

          alert('please fill required data and try again');
          }
        }}


      >

      <Form>
        <div className="w-96 max-w-6xl mx-auto mb-10 sm:mb-32">
        <div className='mb-7'>

        </div>
          {/* {modal && <Modal text='תודה שהצטרפתם למשפחת סטארט2' btnText='תודה' closeModalHandler  = {CloseModalHandler} goToLink='/' />} */}

        <div className="sm:bg-white sm:shadow-md rounded-3xl p-10 ">
          <div className='text-left'>
            <p>
                        Welcome to start2 User Data Deletion Request Form by WeWash Technologies.
                  If you want to delete your data from our servers we need to make sure all you payments are finalized
                  and cleared.
                  Please submit this form to delete your data from our records.
                  We will contact you asap after validating your data
            </p>

          </div>
          <div className='flex justify-center'>
          <h1 className='mt-6 mb-6'> User Data Deletion Request Form </h1>

          </div>

          <div className="sm:flex" >
           <div className="inline w-full">
           <Field className="w-full mb-4 sm:mb-8   rounded-full appearance-none border formBorderFields  py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
           type="text"
           name='firstName'
           maxLength={50}
           placeholder="Name"/>
           <ErrorMessage name="firstName" component="div" className='text-red-600 -mt-[20px] sm:-mt-[35px] mr-4 sm:ml-4 sm:mb-4 mt:-4 mb:4' />

           </div>
          

          </div>
          <div className="sm:flex">
          <div className="inline w-full mt-4 sm:mt-0">
           <Field className="w-full mb-4 sm:mb-8  mt-4 sm:mt-0
           rounded-full appearance-none border 
           formBorderFields  py-4 px-3 text-gray-700 
           leading-tight focus:outline-none focus:shadow-outline" 
           maxLength={50}
           type="text" 
           name='contactPersonPhone'
           placeholder='Phone'/>
           <ErrorMessage  name="contactPersonPhone" component="div" className='text-red-600 -mt-[20px] mr-4 ml-4 sm:-mt-[35px] sm:mr-4 sm:ml-4 sm:mb-4 mt:-4 mb:4' />

           </div>
          

          </div>
          <div className="inline w-full ">
           <Field className="w-full mb-4 sm:mb-8 mt-4 sm:mt-0   rounded-full appearance-none border formBorderFields py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
           name='email'
           maxLength={50}
           type="text" placeholder="Email"/>
             <ErrorMessage name="email" component="div" className='text-red-600 -mt-[20px] sm:-mt-[35px] mr-4 sm:ml-4 sm:mb-4 mt:-4 mb:4' />

           </div>
           <div className="inline w-full ">
           <Field className="w-full mb-4 sm:mb-8 mt-4 sm:mt-0 rounded-2xl appearance-none border formBorderFields py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
           name='reason'
           maxLength={500}
           component="textarea" rows="4" placeholder="reason"/>
             <ErrorMessage name="reason" component="div" className='text-red-600 -mt-[20px] sm:-mt-[35px] mr-4 sm:ml-4 sm:mb-4 mt:-4 mb:4' />

           </div>
         
          
          
          
          <div className="w-full mt-4 sm:mt-0">
          <button
          type='submit'
         
          className="py-4 mb-4 sm:mb-8 w-full hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full buttonText"
          style={{backgroundColor:'#1F1546'}}>
             Request Data Delete
         </button>
          </div>
        </div>
      </div>
      </Form>
      </Formik>


      </div>
      </div>
    );
  }