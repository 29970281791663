import { useEffect, useState } from 'react';
import Modal from './Modal';
import {addCandidate,getRequitemntDays,statusCode} from '../utils/fetcher'
import {Formik,Field,Form, ErrorMessage,useField} from 'formik'
import { Outlet, useNavigate } from 'react-router-dom';
import start2wewash from '../images/Start2WeWash.png'
import { useTranslation } from 'react-i18next';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import he from 'date-fns/locale/he';
//registerLocale('he',he);
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select'
import {checkIfOnlyNumbers, checkIfNospaces, emailChecker,checkForSpecialSymbols } from '../utils/checkFields';
import ReCAPTCHA from 'react-google-recaptcha';






export default function EmplyeesForm() {
  const [t,i18n] =useTranslation("global");
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const referencebyOptions =  [
    { value: 'Flyers', label: 'Flyers' },
    { value: 'Youtube', label: 'Youtube' },
    { value: 'Instagram', label: 'Instagram'},
    { value: 'Facebook', label: 'Facebook' },
    { value: 'Friend', label: t("Form.SO_Friend") },
    { value: 'TikTok', label: 'TikTok' },
    { value: t("Form.SO_Other"), label:t("Form.SO_Other") }
  ]
  const [referenceBySelect,setReferenceBySelect] = useState('');
  const [referenceByFieldText,setReferenceByFieldText] = useState('');
  const [referenceField,setReferenceField] = useState(true);
  const navigate = useNavigate();
  
  const [modal,setModal] = useState(false);
  const [options,setOptions] = useState([]);
  const [visaExpirationDate, setVisaExpirationDate] = useState('');
  const [recruitmentDate,setRecruitmentDate] = useState('')
  const [datePickerDate,setDatePickerDate] = useState(new Date());
  const [capchaHide,setCapchaHide] = useState(true)

  const recapchaKey = process.env.REACT_APP_RECAPTCHAKEY;
  const [capVal,setCapVal] = useState(null);
  
  


  useEffect( ()=>{
  let opt = [];
  if(referenceBySelect === 'Other' || referenceBySelect === 'אחר' || referenceBySelect === 'ካልእ'){
    setReferenceField(false)
  }
  else 
  {
    setReferenceField(true)
  }
  const fetchData = async () => {
    const data = await getRequitemntDays();
    for (let index = 0; index < data.length; index++) {
      let item = new Date(data[index].startTime);
      opt.push({value:item.toISOString(),label:item.toISOString(),id:data[index]._id})
    }
    setOptions(opt);
  }

  // call the function
  fetchData()
    // make sure to catch any error
    .catch(console.error);

  },[referenceBySelect])

  
  const [formErrors, setFormErrors] = useState({});
  const phoneNumberRegex = '[0][0-9]{9}';



  function NavigateToHome(){
    navigate('/')
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' })
    window.location.reload(false);

  }

  function CloseModalHandler(){
    setOpenModal(false);
    NavigateToHome()
    
  }


  const [openModal,setOpenModal] = useState(false)
  const toggleModal = () => {
    setOpenModal(true);
  };
    return (
      <div>
        

        <div className='font-Heebo text-center mt-15 sm:mt-20 mb-6 text-xl font-medium sm:text-5xl sm:font-medium' 
        style={{color: 'rgb(31, 21, 70)'}}><span>{t("SignUp")}</span></div>
      <div className='formik' >
      <Formik
     initialValues={{ 
     firstName: '',
     lastName: '',
     candidateInstructionDate: '', // "2023-10-11T06:27:26.906Z"
     recruitmentDate:'',  // "2023-09-21T06:27:26.906Z"
     phoneNumber: '',
     idNumber: '',
     email: '',
     visaExpirationDate:'',
     nationality: '',
     lngWebsite:'',
     referenceByField:''
      }}
         validate={(values) => {
           const errors = {};
           if (!checkIfNospaces.test(values.firstName) || checkForSpecialSymbols.test(values.firstName)) {
            errors.firstName =t("Errors.RequiredField");
          }
          if (!checkIfNospaces.test(values.lastName) || checkForSpecialSymbols.test(values.lastName)) {
            errors.lastName =  t("Errors.RequiredField");
          }
          // if (!values.idNumber.match(checkIfOnlyNumbers)) {
          //   errors.idNumber =  t("Errors.RequiredField");
          // }
          
         // if (!checkIfNospaces.test(values.email)) {
          if (!(values.email.match(emailChecker))) {
            errors.email =  t("Errors.RequiredField");
          }
          // if (!checkIfNospaces.test(values.nationality)) {
           
          //   errors.nationality =  t("Errors.RequiredField");
          // }
          if (!values.visaExpirationDate) {
            if(visaExpirationDate =='' || visaExpirationDate == new Date()){
              errors.visaExpirationDate = t("Errors.RequiredField");
            }
          }
          if (!values.recruitmentDate) {
            if(recruitmentDate == ''){
            errors.recruitmentDate = t("Errors.RequiredField");
            }
          }
          if (!values.phoneNumber.match(phoneNumberRegex)) {
            
            errors.phoneNumber =  t("Errors.PhoneNumberError");
          }
          setFormErrors(errors);
           return errors;
         }
        }
      
         onSubmit={ async (formData) => {
          formData.recruitmentDate = recruitmentDate;
          formData.visaExpirationDate = visaExpirationDate;
          formData.candidateInstructionDate = '2023-10-27T00:00:00.000Z';
          formData.lngWebsite = i18n.language;
          formData.referenceByField = referenceBySelect === 'Other' ? referenceByFieldText : referenceBySelect;

         await addCandidate(formData);
          if(statusCode == 200){
            setOpenModal(true);
           
          }
          else {
            alert('There was a problem to add candidate!')
          }
        }}
      
      
      >
        
      <Form>
        <div className="w-full max-w-6xl mx-auto mb-10 sm:mb-32">
          <div>

          </div>
        <div className=''>
          {/* <Map/> */}

        </div>
          {openModal && <Modal
          
            text={t("Modal.ModalText")} 
            btnText={t("BtnText.Thanks")} 
            closeModalHandler  = {CloseModalHandler}/>}
       
        <div className="bg-white sm:shadow-md rounded-3xl p-16 ">
          
          <div className="sm:flex" >
           <div className="inline w-full ">
           <Field className="w-full mb-4 sm:mb-8   
           rounded-full 
           appearance-none 
           border 
           formBorderFields  
           py-4 
           px-3 
           text-gray-700 
           leading-tight 
           focus:outline-none 
           focus:shadow-outline" 
           type="text" 
           name='firstName'
           maxLength={50}
           placeholder={t("Form.FirstName")}/>
           <ErrorMessage name="firstName" component="div" className='text-red-600 -mt-[20px] mr-4 ml-4  sm:-mt-[35px] sm:mr-4 sm:ml-4 sm:mb-4 mt:-4 mb:4' />

           </div>
           <div className='hidden sm:block sm:w-[100px]'></div>
           <div className="inline w-full ">
           <Field className="w-full mb-4 sm:mb-8   mt-4 sm:mt-0
           rounded-full appearance-none border 
           formBorderFields py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
           name='lastName'
           maxLength={50}
           type="text" placeholder={t("Form.LastName")}/>
             <ErrorMessage name="lastName" component="div" className='text-red-600 -mt-[20px] mr-4 ml-4  sm:-mt-[35px] sm:mr-4 sm:ml-4 sm:mb-4 mt:-4 mb:4' />

           </div>
            <span>
            </span>

          </div>
          <div className="sm:flex" >
           <div className="inline w-full mt-4 sm:mt-0">
           <Field className="w-full mb-4 sm:mb-8  mt-4 sm:mt-0
           rounded-full appearance-none border 
           formBorderFields  py-4 px-3 text-gray-700 
           leading-tight focus:outline-none focus:shadow-outline" 
           maxLength={50}
           type="text" 
           name='phoneNumber'
           placeholder={t("Form.PhoneNumber")}/>
           <ErrorMessage  name="phoneNumber" component="div" className='text-red-600 -mt-[20px] mr-4 ml-4 sm:-mt-[35px] sm:mr-4 sm:ml-4 sm:mb-4 mt:-4 mb:4' />

           </div>
           <div className='hidden sm:block sm:w-[100px]'></div>
           <div className="inline w-full ">
           <Field className="w-full mb-4 sm:mb-8  mt-4 sm:mt-0 
           rounded-full appearance-none border 
           formBorderFields py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
           maxLength={50}
           name='idNumber'
           type="text" placeholder={t("Form.PassportNumber")}/>
             <ErrorMessage name="idNumber" component="div" className='text-red-600 -mt-[20px] mr-4 ml-4 mr-4 ml-4 sm:-mt-[35px] sm:mr-4 sm:ml-4 sm:mb-4 mt:-4 mb:4' />

           </div>
            <span>
            </span>

          </div>
          <div className="sm:flex">
           
           <div className="w-full 
            mt-4 
            sm:mt-0
            mb-4 
            sm:mb-8  
            relative
            rounded-full appearance-none border 
            formBorderFields   text-gray-700 
            leading-tight focus:outline-none focus:shadow-outline">
            <div className=''>
            <span className='opacity-50 py-4  px-3 inline'>{t("Form.VisaExpiration")} |  </span>
           
           <DatePicker
            name='visaExpirationDate'
            dateFormat = {i18n.dir() == 'rtl' ? 'dd/MM/yyyy' : 'MM/dd/yyyy' } 
            minDate={new Date()}
            onChange={(date) => {setVisaExpirationDate(date);setDatePickerDate(date)}}
            className='inline py-4'
            selected={datePickerDate}
           
            onKeyDown={(e) => {
              e.preventDefault();
           }}
           
             />
               <ErrorMessage name="visaExpirationDate" component="div" className={`text-red-600 -bottom-[20px] sm:-bottom-[34px] ${i18n.dir() == 'rtl' ? 'right-[17px]' : 'left-[17px]'}   absolute sm:-mt-[40px] sm:mb-4 mt:-4 mb:4`} />
               </div>
         
           </div>
           <div className='hidden sm:block sm:w-[100px]'></div>
           
           <div className="inline w-full mt-4 sm:mt-0">
           
           <Field className="w-full mb-4 sm:mb-8
           mt-4 sm:mt-0 
              rounded-full
              appearance-none border 
              formBorderFields py-4 px-3 
              text-gray-700 leading-tight 
              focus:outline-none 
              focus:shadow-outline" 
           maxLength={50}
           name='email'
           type="text" placeholder={t("Form.email")}/>
             <ErrorMessage name="email" component="div" className='text-red-600 -mt-[20px] mr-4 ml-4 sm:-mt-[35px] sm:mr-4 sm:ml-4 sm:mb-4 mt:-4 mb:4' />

           </div>
            <span>
            </span>

          </div>
          <div className="sm:flex " >
           <div className="w-full mt-4 sm:mt-0">
           <Field className="w-full mb-4 sm:mb-8  
           
           rounded-full 
           appearance-none 
           border 
           formBorderFields  
           py-4 px-3 text-gray-700 
           leading-tight focus:outline-none focus:shadow-outline" 
           type="text" 
           maxLength={50}
           name='nationality'
           placeholder={t("Form.Nationality")}/>
           <ErrorMessage name="nationality" component="div" className='text-red-600 -mt-[20px] mr-4 ml-4 sm:-mt-[35px] sm:mr-4 sm:ml-4 sm:mb-4 mt:-4 mb:4' />

           </div>
           <div className='hidden sm:block sm:w-[100px]'></div>
           <div className="w-full">
          <div className="w-full rounded-full mt-4 sm:mt-0">
              <Select
              className="w-full mb-4 sm:mb-8 "
               styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  borderRadius: '1000px',
                  borderColor:'#1F1546',
                  paddingTop:'8px',
                  paddingBottom:'8px',
                }),
              }}
              onKeyDown={(e) => {
              e.preventDefault();
           }}
           
              name ='recruitmentDate'
              options={options.map((option) =>{
                return {value: option.value,label:new Date(option.value).toLocaleDateString('he-IL',{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                  timeZone: 'Asia/Jerusalem'
                  })
                   + ' ' +t("WeekDays",{ returnObjects: true })[new Date(option.value).getDay()]
                   ,id:option.id
                }
              })}
              onChange={(e) =>{setRecruitmentDate({date:e.value,id:e.id})}}
              
              placeholder={t("Form.RequirmentDate")}
              />
               <ErrorMessage name="recruitmentDate" component="div" className='text-red-600 -mt-[20px] mr-4 ml-4 sm:-mt-[35px] sm:mr-4 sm:ml-4 sm:mb-4 mt:-4 mb:4' />
            </div>
           </div>
           
           
          </div>
          <div className='sm:flex'>
          <div className="w-full">
          <div className="w-full rounded-full mt-8 sm:mt-0">
              <Select
              className="w-full mb-4 sm:mb-8 "
               styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  borderRadius: '1000px',
                  borderColor:'#1F1546',
                  paddingTop:'8px',
                  paddingBottom:'8px',
                }),
              }}
              onKeyDown={(e) => {
              e.preventDefault();
           }}
           
              name ='referenceBy'
              options={referencebyOptions}
              onChange={(e) =>{setReferenceBySelect(e.value)}}
              
              placeholder={t("Form.ReferenceBy")}
              />
               <ErrorMessage name="referenceBy" component="div" className='text-red-600 -mt-[20px] mr-4 ml-4 sm:-mt-[35px] sm:mr-4 sm:ml-4 sm:mb-4 mt:-4 mb:4' />
            </div>
           </div>
           <div className='hidden sm:block sm:w-[100px]'></div>
           
           <div className="inline w-full mt-4 sm:mt-0">
           
           <Field className={`${referenceField ? 'opacity-0':'opacity-100'} w-full mb-4 sm:mb-8 mt-4 sm:mt-0  rounded-full appearance-none border  formBorderFields py-4 px-3  text-gray-700 leading-tight focus:outline-none  focus:shadow-outline`}
             
           maxLength={50}
          // value ={ referenceBy}
           //onChange = {((e) => {setReferenceBy(e.target.value)})}
           disabled = {referenceField}
           name='referenceByField'
           value = {referenceByFieldText}
           onChange = {(e)=>{setReferenceByFieldText(e.target.value)}}
           type="text" placeholder={t("Form.ReferenceByName")}/>
             <ErrorMessage name="referenceByField" component="div" className='text-red-600 -mt-[20px] mr-4 ml-4 sm:-mt-[35px] sm:mr-4 sm:ml-4 sm:mb-4 mt:-4 mb:4' />

           </div>
           </div>
          <div className='flex justify-center mt-6 mb-8'>
              <div>
                  <ReCAPTCHA
                   sitekey={recapchaKey} 
                   hl={"en"}
                   onChange={(val) => setCapVal(val)}/>
              </div>
            </div>

          <div className="w-full mt-6  sm:mt-0">
          <button 
          disabled = {capVal ? false : true }
          type='submit'
          className="py-4 mb-4 sm:mb-8 w-full hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full buttonText" 
          style={{backgroundColor:'#1F1546',opacity: !capVal ? '0.5' : '1'}}>
             {t("BtnText.sendApplication")}
         </button>
          </div>
        </div>
      </div>
      </Form>
      </Formik>
     

      </div>
      </div>
    );
  }