import logo from '../images/Logo.png';
import BigBtn from './BigBtn';
import { getLocationPath } from '../utils/pathChecker';
import ReactGA from "react-ga4";
import { useTranslation } from 'react-i18next';

export default function CustomersOrEmplyees() {

  const [t,i18n] =useTranslation("global");

  i18n.changeLanguage('he');
  ReactGA.initialize("G-ZN91JR6X3T");
    
    return (
      <div className='min-h-screen' >
        <div className='flex justify-center pt-6 sm:pt-10'>
        <img className='w-[170px] sm:w-[150px] lg:w-[300px]'  src={logo} alt='logo'/>
        

        </div>
        <div className='flex justify-center px-4'>
            <div className={`main_header_text mt-5 text-xl sm:mt-16 sm:text-4xl sm:max-w-3xl text-center`}>
        <p>{t("header2.heroText")}</p>


      </div>
        </div>
        <div className='flex justify-center px-4'>
        <div className={`main-slogan mt-5  text-base sm:text-2xl sm:max-w-55 text-center`}>
        <p className='mt-2'>
          {t("header2.heroDescText")}
        </p>
        {/* <p className='mt-2'>
          {t("header2.heroDescText1")}
        </p> */}
        <p className='mt-6'>
          <span>{t("header2.LookingWorkers")}</span> <strong>{t("header2.Customers")} </strong> <span>{t("header2.ForMoreInfo")}</span>
        </p>
        <p className='mt-2'>
        <span>{t("header2.LookingForJob")}</span> <strong>{t("header2.Employees")} </strong> <span>{t("header2.SignToRecDay")}</span>
        </p>
        <br/>
       
       {/* <p>{t("header.heroDescText1")}</p> */}
            
      </div>
        </div>
      
        <div className='flex flex-wrap gap-x-8 gap-y-10 font-Monserrat font-semibold justify-center mt-10 sm:mt-16 mb-40 sm:gap-x-64'>

        
        <div onClick = {()=>{ReactGA.event({
                        category: 'Enters',
                        action: 'Clicked on customers'
                        });}}>
            <BigBtn text ='מעסיקים' link ='/customers' />
        </div>

        <div onClick = {()=>{ReactGA.event({
                        category: 'Enters',
                        action: 'Clicked on employees'
                        });}}>
            <BigBtn text ='עובדים' link ='/employes'/>
        </div>
        </div>
      </div>
    );
  }