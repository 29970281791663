import { useEffect } from 'react';
import Icon247 from '../images/247.png'
import awardIcon from '../images/award.png'
import clockSpeedIcon from '../images/clockSpeed.png'
import protectIcon from '../images/protectIcon.png'


 

const ourAdvantagesItemsData = [
    {
    id:1,
    icon:protectIcon,
    altText:'protect icon',
    title:'עבודה על פי חוק',
    text:'start2 נותנת שקט לבית העסק בגין כל טביעה עתידית של העובד כלפיו, היות וstart2 אחראית לתשלומי כלל המיסים החלים על העובד עפ״י חוק וכל זאת תוך שקיפות מלאה של תשלומי המיסים דרך האפליקציה'
    },
    {
        id:2,
        icon:awardIcon,
        altText:'award icon',
        title:'שירות איכותי',
        text:'start2 מגייסת עובדים תוך הקפדה על מיון מקצועי ומדוקדק של העובדים ויצירת רף גבוה מבחינת הסטנדרטים שהיא מציבה בטרם גיוס העובד לאפליקציה'
        
    },
    {
        id:3,
        icon:clockSpeedIcon,
        altText:'clock icon',
        title:'שירות מהיר',
        text:'start2 מאתרת את העובדים הנמצאים במרחק הכי קרוב מהמסעדה. נותנת מענה מהיר ומידי למסעדה אשר נמצאת במצוקה'
        
        
    },
    {
        id:4,
        icon:Icon247,
        altText:'24/7 icon',
        title:'זמינות',
        text:'צוות העובדים והאפליקציה לשירותך בכל רגע נתון'
        
    },





]

export default ourAdvantagesItemsData;