import React, { useEffect, useState } from 'react'
import { HashLink } from 'react-router-hash-link';
import { getLocationPath } from '../utils/pathChecker';


export function ScrollToBtn({text,classes,styles,flx,btnTxtStyle }) {

    const [anchor,setAnchor] = useState('');

    useEffect(()=>{
        if(getLocationPath() == '/customers' || getLocationPath() == '/wewash'){
            setAnchor('/customers#map')
        }
        if(getLocationPath() == '/employes'){
            setAnchor('/employes#employesForm')
        }

    },[window.location.pathname])
    

    return (
        <div>
       <HashLink smooth to={anchor}>

       
        <div className={flx}>
        <div className={classes} style={{backgroundColor: 'rgb(31, 21, 70)'}}>
        <span className={btnTxtStyle}>
         {text}
         </span>

        </div>
        </div>
         </HashLink>
        </div>
    )
}
