



export const pol1 = [
    {lat:32.095926, lng:34.774610},
    {lat:32.095508, lng:34.783864},
    {lat:32.091111, lng:34.783204},
    {lat:32.085409, lng:34.782174},
    {lat:32.075774, lng:34.781939},
    {lat:32.070838, lng:34.781991},
    {lat:32.071983, lng:34.778976},//rotshild -ben zion
    {lat:32.077448, lng:34.768052},// bugrashov - ben yehuda
    {lat:32.079210, lng:34.769114},// ben yehuda - mendale moher sfarim
    {lat:32.085220, lng:34.772426},// ben yehuda - ben gurion
    {lat:32.089537, lng:34.773585},// ben yehuda jabotinsky
    {lat:32.092896, lng:34.774733},// ben yehuda nordau
    {lat:32.095926, lng:34.774610}

   
  ]
 

 export const triangleCoords = [
    {lat:32.033887, lng:34.741939},
    {lat:32.048497, lng:34.744852},
    {lat:32.044793, lng:34.743517},
    {lat:32.055273, lng:34.748772},
    {lat:32.066111, lng:34.759758},
    {lat:32.077364, lng:34.763995},
    {lat:32.087957, lng:34.766058},
    {lat:32.104359, lng:34.775405},
    {lat:32.108523, lng:34.772795},
    {lat:32.121785, lng:34.780746},
    {lat:32.146552, lng:34.791342},
    {lat:32.144018, lng:34.802397},
    {lat:32.139438, lng:34.800508},
    {lat:32.128662, lng:34.795977},
    {lat:32.129850, lng:34.805443},
    {lat:32.124030, lng:34.811228},
    {lat:32.123258, lng:34.823359},
    {lat:32.124268, lng:34.824516},
    {lat:32.127415, lng:34.819747},
    {lat:32.131690, lng:34.818836},
    {lat:32.131958, lng:34.828197},
    {lat:32.128484, lng:34.827355},
    {lat:32.127415, lng:34.836541},
    {lat:32.121358, lng:34.842677},
    {lat:32.119556, lng:34.851227},
    {lat:32.119183, lng:34.851940},
    {lat:32.107403, lng:34.848796},
    {lat:32.108342, lng:34.844593},
    {lat:32.108637, lng:34.842742},
    {lat:32.107326, lng:34.840011},
    {lat:32.105476, lng:34.835747},
    {lat:32.106131, lng:34.827888},
    {lat:32.104987, lng:34.824671},
    {lat:32.104023, lng:34.822790},
    {lat:32.101735, lng:34.820863},
    {lat:32.100433, lng:34.821084},
    {lat:32.098764, lng:34.822038},
    {lat:32.096289, lng:34.819575},
    {lat:32.098148, lng:34.818185},
    {lat:32.098256, lng:34.815582},
    {lat:32.096494, lng:34.814153},
    {lat:32.096127, lng:34.811091},
    {lat:32.096278, lng:34.809356},
    {lat:32.091046, lng:34.809151},
    {lat:32.091233, lng:34.806769},
    {lat:32.093675, lng:34.802040},
    {lat:32.087582, lng:34.800249},
    {lat:32.080109, lng:34.797921},
    {lat:32.077522, lng:34.801719},
    {lat:32.065503, lng:34.802718},
    {lat:32.049782, lng:34.813941},
    {lat:32.041248, lng:34.811812},
    {lat:32.035405, lng:34.806543},
    {lat:32.042471, lng:34.792902},
    {lat:32.044888, lng:34.785035},
    {lat:32.038678, lng:34.780019},
    {lat:32.036384, lng:34.772910},
    {lat:32.037026, lng:34.759738},
    {lat:32.033661, lng:34.756166},
    {lat:32.031017, lng:34.754560},
    {lat:32.030497, lng:34.750933},
    {lat:32.029548, lng:34.749868},
    {lat:32.032562, lng:34.744311},
    {lat:32.033887, lng:34.741939}
     ]

     export const pol1Tamir = 
[
    {
        "lat": 32.091862170206795,
        "lng": 34.78320541745122
    },
    {
        "lat": 32.092689305674725,
        "lng": 34.7745740688413
    },
    {
        "lat": 32.07984558323868,
        "lng": 34.76967314738915
    },
    {
        "lat": 32.07945809400906,
        "lng": 34.7814426805465
    }
]
export const pol2Tamir =
[
    {
        "lat": 32.08898077169463,
        "lng": 34.80389061337407
    },
    {
        "lat": 32.091214545176875,
        "lng": 34.80107161169942
    },
    {
        "lat": 32.09184853604292,
        "lng": 34.78323223954137
    },
    {
        "lat": 32.073536463351836,
        "lng": 34.78067020435021
    },
    {
        "lat": 32.07290575469937,
        "lng": 34.79094842929528
    },
    {
        "lat": 32.07194775107194,
        "lng": 34.79603389758751
    }
]
export const pol3Tamir3 =
[
    {
        "lat": 32.101614620781056,
        "lng": 34.80322542553838
    },
    {
        "lat": 32.10310511795257,
        "lng": 34.77297010785039
    },
    {
        "lat": 32.09292152931718,
        "lng": 34.770273962208485
    },
    {
        "lat": 32.09192510536989,
        "lng": 34.78488127250359
    },
    {
        "lat": 32.09143768308414,
        "lng": 34.80137685794518
    }
]

export const pol4Tamir = 
[
    {
        "lat": 32.092580233294434,
        "lng": 34.77449360257085
    },
    {
        "lat": 32.092798377924964,
        "lng": 34.77033081417974
    },
    {
        "lat": 32.07227276884896,
        "lng": 34.763278761097645
    },
    {
        "lat": 32.07100223824337,
        "lng": 34.77039197940514
    },
    {
        "lat": 32.079846496903215,
        "lng": 34.76969084453874
    }
]
export const pol5Tamir = 
[
    {
        "lat": 32.092580233294434,
        "lng": 34.77449360257085
    },
    {
        "lat": 32.092798377924964,
        "lng": 34.77033081417974
    },
    {
        "lat": 32.07227276884896,
        "lng": 34.763278761097645
    },
    {
        "lat": 32.07100223824337,
        "lng": 34.77039197940514
    },
    {
        "lat": 32.079846496903215,
        "lng": 34.76969084453874
    }
]

export const pol6Tamir =
[
    {
        "lat": 32.06667185560051,
        "lng": 34.77764788037236
    },
    {
        "lat": 32.07093587935126,
        "lng": 34.77059635287221
    },
    {
        "lat": 32.07130863103902,
        "lng": 34.76856592064794
    },
    {
        "lat": 32.07219959233096,
        "lng": 34.76320686703618
    },
    {
        "lat": 32.066885738802995,
        "lng": 34.76080200386338
    },
    {
        "lat": 32.066974496384596,
        "lng": 34.761756068324935
    },
    {
        "lat": 32.0633900492349,
        "lng": 34.766057529637074
    },
    {
        "lat": 32.061622721542435,
        "lng": 34.76665834445641
    },
    {
        "lat": 32.0616556816639,
        "lng": 34.76831058520958
    },
    {
        "lat": 32.06363645411056,
        "lng": 34.77536103439622
    }
]

export const pol7Tamir =
[
    {
        "lat": 32.07286326193699,
        "lng": 34.790189889726
    },
    {
       "lat":32.075858,
       "lng":34.782134
    },
    {
        "lat": 32.07943151298052,
        "lng": 34.781448570546466
       
    },
    {
        
        "lat": 32.079808775352454,
        "lng": 34.76975145703252
    },
    {
        "lat": 32.071002238243324,
       "lng": 34.77041880149529
      
    },
    {
        "lat": 32.06241035154228,
        "lng": 34.78492955226586
    }
]