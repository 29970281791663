import React from 'react'
import Logo from '../../images/Logo.png'

import {useNavigate} from 'react-router-dom';

export function TermsOfUseStart2() {

    const navigate = useNavigate();

    function NavigateToHome(){
        navigate('/');
        window.location.reload(false);
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' })
    
      }
    

    return (
        <div className='max-w-[1000px] m-auto pt-16' dir="rtl">

<div className='2xl:w-60 w-[110px] pt-24  w-36  sm:w-80 sm:pt-36 sm:mb-12 mb-6 m-auto sm:m-1'
        style={{minWidth:'107px'}}
        onClick={NavigateToHome}>
      <img className='cursor-pointer'   src={Logo} alt='logo'/> </div>


                <h2 className='text-2xl sm:text-3xl'>
תנאי שימוש
            </h2>
    	<p>
			
            <br/>
1.	הגדרות
			<br/>
1.	"החברה" ו/או "המעסיק" - ווי וואש טכנולוגיות בע”מ.
			<br/>
2.	"משתמש" – כל אדם המביא במישרין או בעקיפין להפעלת היישומון, לרבות אלה המזינים מידע באמצעות היישומון, או המבקשים לאתר מידע באמצעות היישומון, בין אם הפעולה מבוצעת באמצעות היישומון, ובין אם הפעולה מבוצעת באמצעים אחרים דוגמת גלישה לאתר האינטרנט, שמטרתם גישה למאגר הנתונים של היישומון.
			<br/>
3.	"שירותים" –זכות השימוש ביישומון לצורך ניהול, חיפוש ואיתור עבודות זמניות או משמרות קבועות לתאריך ספציפי לפי קריטריונים אשר נקבעו מראש, וכן לצורך מציאת עבודה זמנית או משמרות קבועות המתאימה לפרופיל העובד והמעסיק.
			<br/>
4.	"עובדים" – משתמש בשירותים לצורך איתור משרה זמנית וקצובה בזמן מתאימה עבורו, אשר נקלט בחברה ומשתמש ביישומון; אישר את התנאים ואת מדיניות הפרטיות להלן וכן אישר את תנאי העסקתו באמצעות "הודעה לעובד" בהתאם להוראות תקנות הודעה לעובד (תנאי עבודה) (צורת הודעה ופרטיה), התשס"ב- 2002, אשר יועברו לו על ידי החברה בתהליך הגיוס.
			<br/>
5.	"יישומון"- הינו תוכנת מחשב שנוצרה על ידי החברה (הרשום במאגר היישומים העולמי של חברת אפל (App store  ) ובמאגר היישומים של חברת גוגל (Google play) בשם Start2Wash  וStart2Client, ומטרתו לקשר בין משתמשים המבקשים לאתר משרה או עובד (בהתאם לאופי המשתמש  והישומון) לפי מידע אישי שמסופק על ידי המשתמשים.
			<br/>
            <br/>
2.	כללי
			<br/>
            
1.	כל האמור בלשון זכר ביישומון, לרבות בתקנון זה, מנוסח מטעמי נוחות בלבד, וכל האמור באלה מופנים לנשים וגברים כאחד.
			<br/>
2.	השימוש ביישומון, לרבות מסירת מידע וחיפוש מידע, משמעו הסכמת המשתמש לכל התנאים להלן, ללא כל סייג, אשר יהוו חוזה התקשרות בין החברה ובין המשתמש לכל דבר ועניין.
			<br/>
3.	החברה שומרת לעצמה את הזכות הבלעדית לשנות, למחוק או להוסיף לתנאים  אלה, בכל עת ומעת לעת, לפי שיקול דעתה הבלעדי, ללא כל צורך במתן הודעה מראש. התנאים המחייבים יהיו אלה המפורסמים ביישומון בעת שימושו האחרון של המשתמש ביישומון. השימוש ו/או העיון ו/או הביקור ו/או הצפייה ו/או הכניסה ו/או ההתחברות ליישומון, למידע בו ולתכניו, לאחר פרסום תנאי שימוש מעודכנים ו/או מדיניות פרטיות עדכנית מהווים את הסכמתך לתנאי השימוש האמורים.
			<br/>
            <br/>
3.	תנאי שימוש
			<br/>
1.	הרשאים להשתמש ביישומון הם אלה: אזרחי מדינת ישראל, תושבי מדינת ישראל, אשר מלאו להם 18 שנים לפחות ואשר אין מניעה על פי דין שישתמשו ביישומון.
			<br/>
2.	לא רשאים לעשות שימוש ביישומון, ובמידע המאוחסן בו, משתמשים אשר חלק מעיסוקם הוא איתור עבודות זמניות, מתן שירותי כוח אדם ו/או שירותי תיווך מסוג כלשהו בין עובד או מועמד לעבודה ובין ומעביד.
			<br/>
3.	מובהר כי השימוש, העיון, הכניסה וההתחברות ליישומון מותרת רק למשתמש אשר נרשם ליישומון ישירות או דרך אתר החברה בכתובת https://start2.co.il או במשרדי החברה ביום גיוס או מול נציג החברה אשר רשם אותו בצורה טלפונית או פרונטלית, אישר את תנאי השימוש והתחבר ליישומון באמצעות פרטי הזיהוי אשר מסר. הרישיון והשימוש ביישומון הינו אישי בלבד, מוגבל בהיקף ובזמן, בלתי עביר ואינו בלעדי. כמו כן, השימוש ביישומון ניתן לשימוש על ידי משתמש אחד בלבד.
			<br/>
4.	המשתמשים אינם מחויבים למסור מידע כלשהו לישומון, לחברה או למי מבעליה. ואולם, קבלת השירותים מותנית וכפופה להשלמתו של הליך הרשמה, במסגרתו יידרש המשתמש למסור פרטים מזהים אודותיו, כפי שייקבע לפי שיקול דעתה הבלעדי של החברה (להלן:"הפרטים האישיים"). הפרטים האישיים ישמרו במאגרי המידע של החברה מסירת פרטים שגויים, התחזות או החסרת פרטים נדרשים תהווה הפרה יסודית של תנאי השימוש ועילה לנקיטת הליכים משפטיים.
			<br/>
5.	הנך מתחייב כי עם רישומך ליישומון ולשירותיו ולחברה וכן בשימושך בתכנים בה- לא תתחזה לכל אדם ו/או גוף ו/או יישות משפטית אחרת.
			<br/>
6.	הנך מתחייב שלא לנצל את היישומון לצורך שליחת הודעות דואר פרסומי ו/או שיווקי ו/או מסחרי לכל צד שלישי.
			<br/>
7.	החברה לא תהא אחראית לכל נזק, פגם, ליקוי ו/או תקלה מכל סוג שהוא, העלולים להיגרם כתוצאה מהורדת היישומון ו/או השימוש בו, בין אם באמצעות הורדת היישומון למעשיר הנייד ובין אם באמצעות גלישה באתר היישומון, כמפורט לעיל.
			<br/>
8.	החברה תהא רשאית להסיר מהיישומון כל מידע שהוא, לרבות משתמשים, הודעות, עובדים וכיוצ"ב, וזאת לפי שיקול דעתה הבלעדי, וללא מתן כל הודעה מראש.
			<br/>
9.	השימוש ביישומון למטרות מסחריות כלשהן, למעט אלה המפורטות באופן מפורש בתקנון זה, שלא באישור מראש ובכתב של החברה, אסורות בהחלט. שימוש למטרות מסחריות לעניין סעיף זה יכלול, בין היתר, שימוש בשירותי היישומון לצורך העברת מידע לגורם שלישי, בין אם המשתמש ו/או עובד יקבל תמורה כלשהי בשל כך ובין אן לאו.
			<br/>
10.	היישומון הינו פרי פיתוחה של החברה ומובא לשימוש כמו שהוא. החברה לא תהא מחוייבת להתאמתו לצרכי המשתמש, למערכות הפעלה מסוג כלשהו, או לבצע בו שינוי כלשהו. יחד עם זאת, החברה תהא רשאית לבצע שינויים ביישומון לפי שיקול דעתה הבלעדי, כפי שתמצא לנכון מעת לעת, וזאת ללא מתן הודעה מוקדמת כלשהי. המשתמש מוותר בזאת על כל טענה שתעלה במישרין או בעקיפין כנגד היישומון, החברה ו/או מי ממפעיליו, בעליו או מי ממנהליו בכל הקשור לאופן פעולת היישומון, לרבות אי פעולתו.
			<br/>
11.	מבלי לגרוע מהאמור בכל דין, תהא רשאית החברה למנוע את השימוש מאדם המפר תנאי כלשהו מהוראות תנאים אלה, למשך זמן מוגדר, לפרקים או לצמיתות, לרבות במצבים אלה:
			<br/>
1.	אם לדעת החברה המשתמש ביצע מעשה הפוגע או העשוי לפגוע בפעילותו התקינה של היישומון, באופן חשיפת מאגר הנתונים שלו ו/או במשתמשים כלשהם הקשורים עם פעילות היישומון, או שעשוי לחשוף מידע, לרבות פרטים אישיים, אשר אין בכוונת החברה או מי ממשתמשיו למסור למבצע הפעולה האמורה.
			<br/>
2.	אם לדעת החברה המשתמש ביצע עבירת מחשבים כאמור בחוק המחשבים, התשנ"ה- 1995.
			<br/>
3.	אם לדעת החברה המשתמש מסר בעת רישומו פרטים כוזבים במתכוון.
			<br/>
4.	אם לדעת החברה המשתמש פועל בחוסר תום לב בקשר לשימוש ביישומון.
			<br/>
יודגש אין באמור בסעיף זה בכדי לגרוע מזכות החברה למצות את זכויותיה כנגד המפר תנאים אלה.  
			<br/>
			<br/>
            <br/>
1.	עלויות השירות
			<br/>
1.	השירותים המוצעים ביישומון לעובדים (כהגדרתם לעיל), ולאלה בלבד, יינתן ללא תשלום. 
			<br/>
2.	השירותים המוצעים ביישומון למעסיקים כרוכים בתשלום חודשי ובתשלום עבור השרות המוזמן, בתעריפים שונים אשר ייקבעו מראש על ידי החברה, ואשר יפורסמו ביישומון מעת לעת.
			<br/>
3.	החברה תהא רשאית לשנות את התעריפים האמורים בכל עת, ואלה יחולו גם בקשר למעסיקים קיימים הצורכים באותה העת את השירותים, ובלבד ששינוי בתעריפים בקשר למשתמשים קיימים יעשה בהודעה של שבעה (7) ימים מראש באמצעים שונים לבחירת החברה.  
			<br/>
4.	תשלום עבור השירותים יבוצע באמצעות כרטיס אשראי של אחת מחברות האשראי הפועלות בישראל כדין, כהגדרתו בחוק כרטיסי חיוב, התשמ"ו – 1986, ובאמצעות כרטיסים אלה בלבד.
			<br/>
5.	ביטול, הפסקה או הקפאת  השירות:
			<br/>
1.	משתמש יהיה רשאי להודיע בכל עת בה יחפוץ על הפסקת השירותים באמצעות היישומון, וזאת בפניה למוקד שירות הלקוחות של החברה ללקוחות בטלפון 055-9752925  ולהשאיר הודעה בווצאפ, באמצעות משלוח דואר אלקטרוני לכתובת    support@wearetech.me או באמצעות פניה בדואר אל החברה- ווי וואש טכנולוגיות בע”מ הגרא  41, ת"א (להלן: "הודעת הביטול").
			<br/>
2.	החברה תחדל לחייב דמי מנוי את המעסיק בתוך 7 ימי עסקים מיום קבלת הודעת הביטול במשרדי החברה או במועד מאוחר יותר המצוין בבקשת הביטול (להלן: "מועד הביטול") והמנוי ישאר בתוקף עד סיום התקופה ששולמה. משתמש לא יהיה זכאי להחזר תשלום ששילם בעבור שעות  עובדים או דמי מנוי ששולמו.
			<br/>
3.	החברה רשאית להפסיק את השימוש שעושה משתמש ביישומון ובאיזה מהשירותים המוצעים בו בשל הפרה של תנאי התקנון או לפי שיקול דעתה הבלעדי והמוחלט של החברה, והכל ללא כל צורך להביא נימוק או הסבר.
			<br/>
2.	הוראות נוספות לעניין שירות למעסיקים
			<br/>
בנוסף לאמור בתנאי השימוש, ומבלי לגרוע מהם, יחולו לגבי מעסיקים העושים שימוש ביישומון גם תנאי השימוש הבאים:
			<br/>
1.	פרסום באמצעות היישומון יעשה עבור משמרת פנויה בלבד.
			<br/>
2.	פרסום, שיווק וקידום מכירות באמצעות היישומון אסורים בהחלט.
			<br/>
3.	המעסיק מתחייב שלא לשמור באמצעי אלקטרוני או פיזי קורות חיים או פרטי התקשרות כלשהם עם מועמדים לעבודה אשר נחשפו בפניו באמצעות היישומון. 
			<br/>
4.	סעיף זה יחול גם לגבי מועמדים לעבודה שהועסקו על ידי המעסיק אגב חשיפת הפרטים.  
			<br/>
5.	המעסיק מתחייב שלא להעסיק עובדים שהועסקו אצלו בצורה ישירה על ידי הישומון ולא הייתה לו חשיפה מוקדמת אליהם לתקופה של 12 חודשים לפחות מיום ההתנתקות משרותי החברה. 
			<br/>
6.	כל המחירים המצוינים ביישומון, הקשורים לשירותים שניתנים עבור מעסיקים, אינם כוללים מע"מ, אלא אם צוין במפורש אחרת.
			<br/>
			<br/>
			<br/>
1.	אחריות החברה
			<br/>
1.	מובהר בזאת כי המידע המועלה ליישומון מבוסס על מידע שנמסר על ידי המשתמשים. משכך, החברה לא תהא אחראית לטיב המידע, מהימנותו ו/או נכונותו. השימוש בישומון, ובכל המידע האגור במערכות המידע שלו, נעשה לפי בחירתו של המשתמש, ולאחר שהבין את הסיכונים הקשורים בכך.  
			<br/>
2.	המשתמש מוותר בזאת על כל תביעה או תלונה שתבוא בקשר לנזק או הפסד שנבעו משימוש, בין אם ניתן היה לצפות את הנזק האמור ובין אם לאו, בין אם בקשר לפרסום מידע שהועלה על ידי המשתמש, ובין אם זה הועלה ליישומון על ידי משתמש אחר, והכל הן בקשר לפעילות היישומון או אי פעילותו, הן בקשר לפרסום מידע, הן בקשר למהימנותו והן בקשר לכל טענה אחרת הקשורה בפעולת היישומון.
			<br/>
3.	החברה לא תשא באחריות או בחבות לכל תוכן המועלה על ידי משתמשים, או אשר בא לידי ביטוי על ידם באמצעות היישומון, לרבות או התנהגות וביטוי דברים מאיימים, משמיצים, מגונים, פוגעניים או לא חוקיים, או לכל הפרת זכויות מצד גורם אחר, כולל זכויות קניין רוחני. בנוסף, החברה לא תהא אחראית לכל תוכן הנשלח באמצעות צד שלישי כלשהו למי ממשתמשי היישומון, בין אם הדבר נעשה בידיעת החברה ובין אם לאו, והמשתמש ביישומון מוותר על כל טענה כאמור.
			<br/>
4.	החברה לא תשא באחריות כלשהי למעשי מי מהמשתמשים בישומון, בין אם יכולה הייתה למנוע את הנזק הרלוונטי ובין אם לאו ובין אם יכלה לצפות את הנזק ובין אם לאו.
			<br/>
5.	החברה לא מתחייבת להגעת העובד למשמרת ועל המשתמש לעקוב אחר ציר הזמן בישומון ובמידה ועובד לא זמין יש להזמין אחר. 
			<br/>
6.	החברה תקבל מידע מהמשתמשים על עובדים אשר יתנהגו בצורה לא תקינה ונאותה ותעמיד אותם לשימוע ואף פיטורים. 
			<br/>
7.	החברה לא מתחייבת למציאת עובד למשמרת הדבר תלוי במשתמשי הישומון וזמינותם. 
			<br/>
8.	המשתמשים מבינים כי החברה מספקת שירות נוסף אשר בא לסייע להעסקת עובדים בזמנים קצרים אך אינם מתערבים בשיבוצים והדבר נעשה דיגיטלית על פי שימוש בישומון של העובדים. 
			<br/>
9.	בעשיית שימוש ביישומון, כמו בקשר ליתר סעיפי תקנות זה, מביע המשתמש הסכמה לתוכן סעיף זה, בכל האמור בו, וללא כל סייג.
			<br/>
			<br/>
			<br/>
			<br/>
			<br/>
2.	איסוף ושימוש במידע על ידי ההחברה
			<br/>
1.	הפרטים הנמסרים על ידי המשתמש יישמרו על ידי החברה במאגרי המידע שברשותה או בשימושה.
			<br/>
2.	החברה תעשה במידע שימוש כפי שתמצא לנכון, ואף תעבירו לגורמים שלישיים ללא צורך בקבלת אישור ו/או רשות המשתמש, והכל בתנאי ששימוש כאמור יעשה על ידי החברה בכפוף להוראות כל דין.
			<br/>
3.	החברה תהא רשאית לעשות שימוש במידע, בין היתר, לשם שיפור השירותים המוצעים על ידה או על מנת ליצור קשר עם המשתמש ולעדכן אותו בדבר שירותים נוספים המוצעים על ידה או על ידי אחרים.
			<br/>
1.	אבטחת מידע
			<br/>
1.	החברה עושה כמיטב יכולתה על מנת לאבטח את היישומון ואת המידע אשר נמסר על ידי המשתמשים במסגרת השימוש וזו אחראית לאבטחת המידע שבמאגר. עם זאת, מובא לידיעת המשתמש כי לא קיימת בנמצא טכנולוגיה המאפשרת הגנה מוחלטת מחשיפת מידע הנמסר על ידי המשתמש, וזאת לאור העובדה כי היישומון מקושר לרשת האינטרנט, וחשוף מפני פגיעות זדוניות של גורמים שלישיים.
			<br/>
2.	משתמש המוסר פרטי מידע ליישומון, לרבות זה המוסר פרטים אישיים, נדרש להביא עובדה זו בחשבון טרם מסירת הפרטים על ידו, ולקחת על עצמו אחריות מלאה לחשיפת פרטי המידע בפני גורמים שלישיים כלשהם.
			<br/>
3.	היה ולמרות אמצעי האבטחה תתבצע חדירה בלתי חוקית ליישומון ו/ או למידע, לרבות זליגת מידע על ידי גורם שאינו מוסמך לכך, יהיה מקורו אשר יהיה, החברה לא תשא באחריות בשל כך, לרבות במקרים בהם פרטים שנמסרו על ידי המשתמש הועברו לשימוש על ידי מי שהשיג את המידע שלא כדין, והמשתמש מוותר בזאת על כל טענה בקשר לכך כנגד החברה ומי מטעמה.
			<br/>
4.	
			<br/>
2.	קניין רוחני וזכויות שימוש
			<br/>
1.	כל זכויות הקניין הרוחני הקשורות ביישומון, לרבות כל המידע הכלול בו, וכן התכנים, לרבות מלל, איורים, גרפיקה, צליל, יישומי תוכנה, גרפים, תמונות ואופן הצגתו הינם רכושה של החברה, או של אחרים אשר התירו לחברה לעשות בהם שימוש, ואין לעשות בהם שימוש כלשהו ללא קבלת אישור בכתב ומראש מאת החברה.
			<br/>
2.	אלא אם נאמר אחרת בהסכם מפורש, השימוש ביישומון יכול שיעשה על ידי המשתמש לשימושו האישי בלבד. "שימוש אישי" לעניין סעיף זה משמעו שימוש שיעשה לצרכי המשתמש בלבד, בין אם המשתמש הינו יחיד ובין אם תאגיד.  
			<br/>
3.	המשתמש אינו רשאי להעתיק, לשנות, להפיץ, לשדר, להציג, לבצע, לשכפל, לפרסם ולאחסן תוכנו של המידע האגור במחשבי החברה או הקשור ביישומון, או כל חלק ממנו וכל תוכן אחר שהתקבל באמצעותו, באופן זמני או קבוע, וזאת ללא קבלת אישור מראש ובכתב מאת החברה.
			<br/>
4.	המשתמש, ככל שהינו תאגיד, מאשר בזאת לחברה לעשות שימוש בשמו הרשמי, שמו המסחרי ובסמלילו, וזאת במסגרת פרסומי החברה בקשר לזהות המשתמשים ביישומון, בין אם השימוש יעשה לשם יחסי ציבור וקידום מכירות, ובין אם יעשה למטרות מסחריות אחרות שתכליתן פרסום והפצת היישומון.
			<br/>
5.	כל אדם זכאי לעיין בעצמו, או על ידי בא-כוחו שהרשהו בכתב, או על ידי אפוטרופוסו, במידע עליו המוחזק במאגר המידע של החברה. אדם שעיין במידע הנוגע אליו ומצא כי אינו נכון, רשאי לפנות לחברה בבקשה לתקן את המידע או למוחקו. פניה כאמור בבקשה לעיין במידע האגור המערכת המידע של היישומון יש להפנות לכתובת דוא"ל office@wearetech.me / support@wearetech.me .
			<br/>
6.	הנך מתחייב כי לא תפרסם ולא תעלה ליישומון בכל דרך שהיא תכנים או חומרים שיש בהם משום פגיעה בזכויות יוצרים ו/או סימן מסחר ו/או מהווים פגיעה בשמו הטוב ו/או מעוולים בלשון הרע ו/או פוגעים בפרטיותו של כל אדם ו/או כל גוף; ו/או מסיתים לאלימות ו/או מעלים תכנים בעלי אופי מיני ו/או אופי הפוגע ברגשות הציבור.
			<br/>
3.	שימוש אסור ו/או לא חוקי
			<br/>
1.	חל איסור להשתמש ביישומון ובתכנים לכל מטרה לא חוקית או לכל מטרה האסורה בתנאי שימוש אילו. המשתמש אינו רשאי להשתמש ביישומון ובתכנים באופן העלול להזיק, לשתק, לגרום עומס יתר או לפגוע ביישומון, ברשתות תקשורת ובציוד תקשורת כלשהו, או להפריע לשימוש של גורם אחר כלשהו ביישומון. המשתמש אינו רשאי לנסות לקבל גישה ללא הרשאה ליישומון, לתכנים, לחשבונות של אחרים או למערכות מחשבים או רשתות המחוברות בכל אמצעי ו/או שיטה אחרים לרבות לבצע ניסוי חוזר ונשנה של סיסמאות, בין באמצעי ישיר ובין באמצעי ממוכן (Password Mining).
			<br/>
2.	מבלי לגרוע מכלליות האמור לעיל, המשתמש מסכים לא לאסוף, לשמור, להעתיק או להעביר לגורם שלישי כלשהו רשימות של משתמשים ומנויים, או מידע אחר לגבי משתמשים (לרבות סיסמאות), ולא להשתמש במידע מסוג זה לאחר, בין אם המידע הושג על ידו באמצעי כשר ובין אם לאו.
			<br/>
3.	המשתמש אינו רשאי להשיג או לנסות להשיג תכנים או מידע כלשהו באמצעים שלא הועמדו לרשות המשתמש באופן מכוון על ידי החברה, ואינו רשאי לשלוח או לגרום לשליחה של שאילתות אוטומטיות מכל סוג שהוא ליישומון או למי ממשתמשיו.
			<br/>
4.	סמכות שיפוט
			<br/>
לבית המשפט המוסמך בעיר תל אביב-יפו תהא סמכות השיפוט הבלעדית בכל הקשור לתנאי שימוש אלה.
			<br/>
1.	שונות
			<br/>
1.	האמור בלשון זכר - אף לשון נקבה במשמע; האמור בלשון יחיד - אף לשון רבים במשמע.
			<br/>
2.	תנאי שימוש אילו יהיו כפופים לדיני מדינת ישראל. מוסכם כי לבתי המשפט המוסמכים במחוז תל-אביב-יפו מוקנית הסמכות הייחודית לדון בכל עניין ומחלוקת הנוגעים להסכם זה ולשימוש ביישומון.
			<br/>
			<br/>
			<br/>
		</p>

            
        </div>
    )
}
