import React from 'react'
import logo from '../images/Logo.png';
import {useNavigate} from 'react-router-dom';

export function Logo() {

    const navigate = useNavigate();


    function NavigateToHome(){
        navigate('/');
        window.location.reload(false);
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' })
    
      }

    return (
       
        <div className='2xl:w-60 w-[110px] pt-24  w-36  sm:w-80 sm:pt-36 '
        style={{minWidth:'107px'}}
        onClick={() =>{NavigateToHome()}}
       >
      <img className=''  src={logo} alt='logo'/> </div>
            
       
    )
}
