import axios from "axios";
import ReactGA from "react-ga4";

export let statusCode;
const baseURL = process.env.REACT_APP_BASEURL;
const shouldRegisterBusiness = process.env.REACT_APP_SHOULDREGISTERBUSINESS;

const headers = {
    'api-key': process.env.REACT_APP_APIKEY,
    'mf-api-key': process.env.REACT_APP_MFAPIKEY,
};
const multipartHeaders = {
    'api-key': process.env.REACT_APP_APIKEY,
    'mf-api-key': process.env.REACT_APP_MFAPIKEY,
    "Content-Type": "multipart/form-data",
};


export const createRestaurant = async (params) => {
    ReactGA.initialize("G-ZN91JR6X3T");
    const formData = new FormData();
    formData.append('location', params.address?.coordinates);
    formData.append('city', params.address?.city);
    formData.append('address', params.address.address);
    formData.append('profilePicture', params.businessLeadLogo);
    formData.append('ltdNumber', params.businessNumber);
    formData.append('contactPersonPhone', "+972" +  params.contactPersonPhone.substring(1));
    formData.append('email', params.email);
    formData.append('authorizedSigner', params.firstName + ' ' + params.lastName);
    formData.append('contactPersonName', params.firstName + ' ' + params.lastName);


   // console.log(shouldRegisterBusiness);
    if (shouldRegisterBusiness === 'true') {
        if (params.isLead) {
            try {
                let response
                formData.append('name',params.name)
                response = await axios.post(`${baseURL}/admin-api/restaurants`, formData, { headers: multipartHeaders });
                ReactGA.event({
                    category: 'Registration',
                    action: 'Created lead in rest SHLDREGBIS true '
                    });
                return response;
            }
            catch (error) {
                ReactGA.event({
                    category: 'Registration',
                    action: 'Created lead in rest SHLDREGBIS true(err) '
                    });
                return error.response.data.errors;
            }

        }
        else {
            try {
                formData.append('name',params.name)
                const response = await axios.post(`${baseURL}/admin-api/businesses`, formData, { headers: multipartHeaders });
                ReactGA.event({
                    category: 'Registration',
                    action: 'Created bis SHLDREGBIS true '
                    });
                return response;
            }
            catch (error) {
                ReactGA.event({
                    category: 'Registration',
                    action: 'Created bis SHLDREGBIS true(err)'
                    });
                return error.response.data.errors;
            }

        }
    }

    else {
        if (params.isLead) {
            try {
                formData.append('name', 'Lead- ' + params.name)
                let response
                response = await axios.post(`${baseURL}/admin-api/restaurants`, formData, { headers: multipartHeaders });
                ReactGA.event({
                    category: 'Registration',
                    action: 'Created ld rest SHLDREGBIS-false'
                    });
                return response;
            }
            catch (error) {
                ReactGA.event({
                    category: 'Registration',
                    action: 'Created ld rest SHLDREGBIS-false(err)'
                    });
                return error.response.data.errors;
            }

        }
        else {
            try {
                formData.append('name', params.name)
                let response
                response = await axios.post(`${baseURL}/admin-api/restaurants`, formData, { headers: multipartHeaders });
                ReactGA.event({
                    category: 'Registration',
                    action: 'Created rest SHLDREGBIS-false'
                    });
                return response;
            }
            catch (error) {
                ReactGA.event({
                    category: 'Registration',
                    action: 'Created rest SHLDREGBIS-false(err)'
                    });
                return error.response.data.errors;
            }

        }


    }
};

export const unsubscribe = async (params) => {
    ReactGA.initialize("G-ZN91JR6X3T");

    await axios.post(`${baseURL}/unsubscribe-api/unsubscribe`, params, {
        headers

    })

        .then(function (response) {
            statusCode = response.status;
            ReactGA.event({
                category: 'Unsubscribe',
                action: 'Unsubscribed'
                });
        })
        .catch(function (error) {
            ReactGA.event({
                category: 'Unsubscribe',
                action: 'Unsubscribed -error'
                });
            console.log(error);
        });
};

export const getRestaurants = async () => {
    const response = await axios.get(`${baseURL}/admin-api/restaurants`, {
        headers
    });
    return response.data;
};

export const getRestaurantsAddresses = async () => {
    const response = await axios.get(`${baseURL}/admin-api/employees/maps`, {
        headers
    })
    return response.data.data;

}

export const addCandidate = async (params) => {
    ReactGA.initialize("G-ZN91JR6X3T");
    await axios.post(`${baseURL}/candidates-api/candidates`, params, {
        headers

    })
        .then(function (response) {
            statusCode = response.status;
            ReactGA.event({
                category: 'Candidates',
                action: 'Candidate was added to rec day'
                });
        })
        .catch(function (error) {
            ReactGA.event({
                category: 'Candidates',
                action: 'Candidate was added to rec day(err)'
                });
            console.log(error);
        });

}

export const getRequitemntDays = async () => {
    let filteredResults = []
    const response =
        await axios.get(`${baseURL}/candidates-api/candidates/recruitment-days?minDate=` + new Date(Date.now()).toISOString(), {  // ?minDate=    + new Date(Date.now()).toISOString()
            headers
        })
    response.data.data.results.forEach(item => {
        if (item.candidates.length < item.candidatesAmount)
            filteredResults.push(item)

    });
    return filteredResults;
}
