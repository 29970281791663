import washIcon from '../images/WashIcon.png'
import cleanIcon from '../images/Start2CleanIcon.png'
import careIcon from '../images/start2careIcon.png'
import cookIcon from '../images/start2cookIcon.png'


const cardItemsData = [
    {
    key:1,
    icon:washIcon,
    altText:'wash icon',
    title:'Start 2 wash',
    text:'פלטפורמה דיגיטלית המתמחה בהנגשת שוטפי כלים, עוזרי טבח ועובדי ניקיון כללי למסעדות, ברים, אולמות אירועים, מטבחים מוסדיים, אירועים פרטיים וחברות קייטרינג.',
    fontS:'16',
    fontWeight:'500'
    },
    {
        key:2,
        icon:cleanIcon,
        altText:'clean icon',
        title:'Start 2 clean',
        text:'בקרוב',
        fontS:'24',
        fontWeight:'600'
        
    },
    {
        key:3,
        icon:cookIcon,
        altText:'cook icon',
        title:'Start 2 cook',
        text:'בקרוב',
        fontS:'24',
        fontWeight:'600'
        
    },
    {
        key:4,
        icon:careIcon,
        altText:'care icon',
        title:'Start 2 care',
        text:'בקרוב',
        fontS:'24',
        fontWeight:'600'
        
    },





]
export default cardItemsData;  