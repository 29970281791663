import workerImg from "../images/workerImage.png"
import Vacancies from "./Vacancies";
import JoinBtn from "./JoinBtn";
import { Outlet } from "react-router-dom";
import EmbeddedVideo from "./EmbeddedVideo";
import { useTranslation } from 'react-i18next';
import { ScrollToBtn } from './ScrollToBtn';

export default function WeWash(props) {
  const [t,i18n] =useTranslation("global");
    
    return (
      <div>
      <div className="innerWrapper">
        <div className="font-Heebo font-medium text-lg sm:text-5xl sm:mb-16 mb-4">
           <span style={{color:'#1f1546'}}>
            {t("OurModel")}
           </span>
        </div>
        <div className="flex justify-between sm:gap-x-8">
            <div >
                <div 
                className="max-w-6xl ourModel_paragraph text-base sm:text-2xl  text-start">
                  <div>
                <p> 
                Start2wash מאתרת את העובדים הנמצאים במרחק הכי קרוב מבית העסק ונותנת מענה מהיר ומידי לבית העסק אשר נמצא במצוקה מיידית. (באם מדובר בשוטף כלים, עובד ניקיון, עוזר טבח או כל עובד אחר אותו החברה מספקת). העובדים ב Start2 עוברים תהליך השמה מדוקדק בהתאם לקטגוריה בה הם מעוניינים לעבוד תוך הצבת רף סטנדרטים גבוה, וזאת על מנת להנגיש לבתי העסק עובד מקצועי ומהימן ביותר. Start2
 נותנת שקט תעשייתי לבית העסק בגין כל תביעה עתידית של העובד ויחסי עובד מעביד לא יתקיימו בין העובד לבית העסק, היות ש Start2 אחראית לתשלומי כל המיסים החלים על כל עובד ועובד. 

</p>
</div>
            <div>
               <Vacancies/>
            </div>
            </div>

            <div 
            className="w-full h-96 bg-no-repeat bg-cover mt-9 sm:hidden"
            style={{backgroundImage:`url(${workerImg})`}}
           >
                    {/* <img className="object-contain" src={workerImg} alt ="worker cooking"></img> */}
                    </div>
            
             <div className='mt-10 mb-12'>
             <ScrollToBtn text={t("BtnText.joinNow")} classes="py-4 px-8  rounded-full cursor-pointer" flx='flex' btnTxtStyle ="text-white buttonText"/>
      </div>
               
            </div>
        
           
            <div className="workerImage hidden sm:block ">
                    <img className="object-contain" src={workerImg} alt ="worker cooking"></img>
                    </div>

        </div>
       
                 
      </div>
      </div>
    );
  }