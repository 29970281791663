import { useState } from 'react';
import Modal from './Modal';
import {createRestaurant,statusCode} from '../utils/fetcher'
import {Formik,Field,Form, ErrorMessage} from 'formik'
import Map from './Map';

export default function ImmediateOrderForm() {



  const [formErrors, setFormErrors] = useState({});
  const phoneNumberRegex = '[0][0-9]{8,9}';

  

   


  const [openModal,setOpenModal] = useState(false)
  const toggleModal = () => {
    setOpenModal(true);
  };
    
    return (
      <div className='formik'>
        
      
      <Formik
      initialValues={{ 
      firstName: '',
      lastName:'',
      name:'',
      address:'',
      businessNumber:'',
      contactPersonPhone:''}}
         validate={(values) => {
           const errors = {};
           if (!values.name) {
             errors.name = "שדה חובה";
           }
           if (!values.firstName) {
            errors.firstName = "שדה חובה";
          }
          if (!values.lastName) {
            errors.lastName = "שדה חובה";
          }
          if (!values.businessNumber) {
            errors.businessNumber = "שדה חובה";
          }
          if (!values.contactPersonPhone.match(phoneNumberRegex)) {
            errors.contactPersonPhone = "מספר טלפון לא תקין";
          }
          setFormErrors(errors);
           return errors;
         }}
      
         onSubmit={ async (formData) => {
          formData.contactPersonPhone = "+972" + formData.contactPersonPhone.substring(1);
          await createRestaurant(formData);
          if(statusCode == 200){
            setOpenModal(true);
          }
          else {
            alert('There was a problem to create restaurant card!')
          }
        }}
      
      
      >
      <Form>
        <div className="w-full max-w-6xl mx-auto mt-140 sm:mt-1080 ">
        <div className='mb-7'>
          {/* <Map/> */}

        </div>
          {openModal && <Modal/>}
       
        <div className="sm:bg-white sm:shadow-md rounded-3xl px-10 pb-10">
          <div className='text-center pt-4 pb-4'>
            <span className='main-slogan'>טופס מילוי פרטים</span>
          </div>
          <div className="sm:flex" >
           <div className="inline w-full ml-5">
           <Field className="w-full mb-4 sm:mb-8  rounded-full appearance-none border formBorderFields  py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
           type="text" 
           name='firstName'
           placeholder="*שם"/>
           <ErrorMessage name="firstName" component="div" className='text-red-600' />

           </div>
           <div className="inline w-full ">
           <Field className="w-full mb-4 sm:mb-8   rounded-full appearance-none border formBorderFields py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
           name='lastName'
           type="text" placeholder="*שם משפחה:"/>
             <ErrorMessage name="lastName" component="div" className='text-red-600' />

           </div>
            <span>
            </span>

          </div>
          <div className="w-full ">
          <Field className="w-full mb-4 sm:mb-8  rounded-full appearance-none border formBorderFields py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
          name ='name'
          type="text" placeholder="*שם חברה:"/>
           <ErrorMessage name="name" component="div" className='text-red-600' />
          </div>
          <div className="w-full ">
          <Field dir='ltr' className="w-full mb-4 sm:mb-8 text-right  rounded-full appearance-none border formBorderFields py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
            name = 'contactPersonPhone'
          type="text" placeholder="מספר טלפון נייד*"/>
           <ErrorMessage name="contactPersonPhone" component="div" className='text-red-600' />
          </div>
          <div className="w-full">
          <Field className="w-full mb-4 sm:mb-8  rounded-full appearance-none border formBorderFields py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
           name = 'businessNumber'
           type="text" placeholder="*מייל"/>
            <ErrorMessage name="businessNumber" component="div" className='text-red-600' />
          </div>
          
          <div className="w-full  ">
          <Field className="w-full mb-4 sm:mb-8  rounded-full appearance-none  formBorderFields py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
           name ='address'
           type="number" min="9" placeholder="כמות העובדים"/>
           <ErrorMessage name="address" component="div" className='text-red-600' />
          </div>
          <div className="w-full  ">
          <Field className="w-full mb-4 sm:mb-8  rounded-full appearance-none  formBorderFields py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
           name ='address'
           type="text" placeholder="תאריך האירוע"/>
           <ErrorMessage name="address" component="div" className='text-red-600' />
          </div>
          <div className="w-full">
          <button 
          type='submit'
          className="py-4 mb-4 sm:mb-8 w-full hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full buttonText" 
          style={{backgroundColor:'#1F1546'}}>
             שלח טופס
         </button>
          </div>
        </div>
      </div>
      </Form>
      </Formik>
      

      </div>
    );
  }