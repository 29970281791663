import footeLogo from './../images/logoFooter.png'
import React, { useEffect, useState } from 'react';
//import linkedInIcon from "../images/LinkedInIcon.png"
import facebookIcon from "../images/FacebookIcon.png"
import { useNavigate, Link } from 'react-router-dom';
import footerBackgroundImage from "../images/FooterBackground.png"
import { useTranslation } from 'react-i18next';
import LanguagesBtns from './LanguagesBtns';
import { getLocationPath } from './../utils/pathChecker';
import { ScrollToBtn } from './ScrollToBtn';
import appStoreLogo from '../images/AppStore.png'
import goolePlayLogo from '../images/GooglePlay.png'
import kenes from '../images/KENESMISADANUT.png'

export default function Footer() {
    const navigation = useNavigate();
    const [t, i18n] = useTranslation("global");
    const [showJoin, setShowJoin] = useState(false);
    const [showApp,setShowApp] = useState('http://tinyurl.com/4kshkk4k') // app store
    const [playstoreApp,setPlaystoreApp] = useState('');
    const [kenesMis,setKenesMis] = useState(false)

    useEffect(() => {
        if (getLocationPath() === '/') {
            setShowJoin(false)
        }
      
        else {
            setShowJoin(true)
        }
        if(getLocationPath() === '/employes')
        {
            setShowApp('http://tinyurl.com/y6e8pnr6');
            setPlaystoreApp('https://play.google.com/store/apps/developer?id=WeAreTech') // must be changed after emp app in playstore
        }
        if(getLocationPath() === '/customers')
        {
            setKenesMis(true);
            setShowApp('http://tinyurl.com/dmc7tssp');
            setPlaystoreApp('https://play.google.com/store/apps/details?id=com.wearetech.wewashbusiness')
        }


    }, [navigation])



    function NavTo(url) {
        navigation(url);
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }


    return (
        <div>
            {/* <div className='hidden md:hidden 2xl:block 2xl:-mb-20 xl:-mt-37 2xl:relative'
        style={{zIndex:'-100'}}
        >
        <img src={footerBackgroundImage}/>
         </div> */}
            <div className='px-20' style={{ backgroundColor: '#1F1546', color: 'white' }}>
               



                <div className="
        pt-16
        pb-10  
        sm:justify-center
        md:flex-none
        md:w-full
        lg:flex
        lg:justify-evenly	 
        xl:justify-center 
        xl:gap-x-12 
        2xl:justify-center 
        2xl:gap-x-12">
                    <div className="">
                        <img className='mx-auto mt-0 sm:mt-[30px]' src={footeLogo} alt="footer logo" />
                        <div className='flex justify-center hidden sm:flex sm:m-auto mt-[30px] sm:mt-[50px]'>
                            <a href='https://tinyurl.com/bdvv6v6a' target='_blank'>
                                <img src={facebookIcon} alt="facebook we wash" />

                            </a>

                        </div>
                    </div>
                    <div className="footer_wrapper_item py-8 sm:py-0 sm:pt-6 text-center">
                        <div>
                            <div className="start2WashText sm:text-2xl font-Monserrat">
                                <span>{t("Footer.Start2Wash")}</span>
                            </div>
                            {/* <div className='mt-6 sm:mt-0'>
                                <a href="#" >
                                    {t("Footer.Information")}
                                </a>
                            </div> */}
                            <div>
                                <a className='cursor-pointer' onClick={() => window.open(process.env.REACT_APP_WHATSAPPURL)}>
                                    {t("Footer.Support")}
                                </a>

                            </div>
                            <div>
                                {showJoin && 
                                <ScrollToBtn text=  {t("Footer.JoinNow")} /> // scroll to
                                  
                               
                                  
                                
                                }

                            </div>
                            <div>
                                <div className='cursor-pointer' onClick={() => NavTo('/AccesibilityText')}>
                                  {t("Footer.AccesibilityTerms")} 
                                </div>
                                <div className='cursor-pointer' onClick={() => NavTo('/TermsOfUseStart2')}>
                                    {t("Footer.TermsAndCond")}
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="footer_wrapper_item sm:pt-6 text-center">
                        <div >
                            <div className='sm:text-2xl font-Monserrat'>
                                {t("Footer.ContactUs")}
                            </div>
                            <div>
                                <a href='mailto:info@wearetech.me'>
                                    {t("Footer.Mail")}
                                </a>

                            </div>
                            <div onClick={() => window.open('tel:'+`${t("Footer.Phone")}`)}>
                                {t("Footer.Phone")}

                            </div>

                        </div>
                    </div>
                    <div className="footer_wrapper_item sm:pt-6 text-center mt-6 sm:mt-0">
                        <div>
                            <div className='sm:text-2xl font-Monserrat'>

                                {t("Footer.Direction")}

                            </div>
                            <div>
                                <a href='https://maps.app.goo.gl/K7rr1tJDEFngtrWq5' target='_blank'>
                                    {t("Footer.Address")} </a>
                            </div>


                        </div>
                    </div>
                   {showJoin && <div className='mt-4  sm:mt-[30px]'>
                            {/* <img src={linkedInIcon} alt="LinkedIn we wash"/> */}

                            {/* employees app */}
                           
                          <div className='flex justify-center mt-6 sm:mt-0'>
                            <a href={showApp} target="_blank" >
                           <img className='w-28 ' src={appStoreLogo} alt ="app store logo"/>
                            </a>
                          </div>
                          <div className='flex justify-center mt-[15px] sm:mt-[15px]'>
                            <a href={playstoreApp} target="_blank" >
                           <img className='w-28'  src={goolePlayLogo} alt ="goole play logo"/>
                            </a>
                          </div>
                   
                           
                        </div>
}      
                     <div className='flex justify-center pt-7'>
                    <div>
                         <img className='w-80' src={kenes} alt = "כנס מסעדנות"/>
                    </div>
                   
                    </div>



                </div>
                <div className='relative '>
                    {/* <div className='mb-6 sm:bottom-[230px] flex justify-center sm:text-2xl' >
        <div className={`font-Monserrat flex gap-x-2 font-semibold`}
        style={{color:'#FA8416'}}>
        <LanguagesBtns/>
        </div>
        </div> */}



                    <div className="footer_wrapper_social flex gap-x-4  justify-center sm:justify-end pb-8">
                     
                        {/* <div >
                             <img src={linkedInIcon} alt="LinkedIn we wash"/> 
                            <a href='http://tinyurl.com/y6e8pnr6' target="_blank">
                           <img src={goolePlayLogo} alt ="play store logo"/>
                            </a>
                        </div> */}


                        <div className='sm:hidden'>
                            <a href='https://tinyurl.com/bdvv6v6a' target='_blank'>
                                <img src={facebookIcon} alt="facebook we wash" />

                            </a>

                        </div>

                    </div>
                    </div>


            </div>
        </div>
    );
}