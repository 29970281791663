import { Link,useNavigate } from "react-router-dom";


export default function CardItem(props) {
  
   const navigate = useNavigate();
  
 function OnCardClickHandler(){
  // if(props.title == 'Start 2 wash'){
  //    navigate('/wewash',{sublogo:true,state:'Clients'});
  //    window.scrollTo({top: 0, left: 0, behavior: 'smooth' })
     
  // }
  
  }
   

    
    return (
      <section className="card">
       <div className="card-item mx-auto w-[300px] mb-20" onClick={OnCardClickHandler}  >
       
                        <div>
                       <img 
                       className="mx-auto pt-10"
                       src={props.icon} 
                       alt={props.altText}/>
                       <div className="card-item-header text-2xl">
                        <span>
                         {props.title}  
                           </span>
                       </div>
                       <div className='card-item-text p-4' style={{fontSize:`${props.fontS}` + 'px',fontWeight:`${props.fontWeight}`}}>
                        <span>
                         {props.text} 
                        </span>
                       </div>
                    </div>
                    </div>
                    </section>

        
    );
  }