import React from 'react'

export function DirectMailerMapPol5(props) {
    

    return (
        <div>
          <iframe src="https://www.google.com/maps/d/u/1/embed?mid=1IDLHdwjRzuTAq5Exgc0uW_o6LR8Mcpw&ehbc=2E312F" width="100%" height="800px"></iframe>
        </div>
    )
}
