import cardItemsData from '../cardItemsData/cardItemsData';
import CardItem from '../components/CardItem'
import { useTranslation } from 'react-i18next';

export default function Card(props) {

  const [t,i18n] =useTranslation("global");
    
    return (
      <div className='m-auto max-w-[1365px] innerWrapper mx-4 sm:m-auto'>
      <div className='flex justify-start nameOfService text-lg sm:mb-6 mb-6 sm:text-5xl sm:mt-[250px] mt-[50px]'>
      <span>
      {t("OurProducts")}  
         </span>
    </div>
      <div className='lg:flex lg:flex-wrap justify-center gap-x-6 '>
      {cardItemsData.map(item=>{
        return <CardItem key ={item.key} {...item}/>
      })}
      </div>
      </div>
    );
  }