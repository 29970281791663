import React from 'react'

export function DirectMailerMapPol3(props) {
    

    return (
        <div>
            <iframe src="https://www.google.com/maps/d/u/1/embed?mid=1rFoG17G-McEpbM_LWHGCRdpMkV34GXA&ehbc=2E312F&noprof=1&zoom=9" width="100%" height='800px' ></iframe>
        </div>
    )
}
