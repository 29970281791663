import { useEffect } from 'react';
import {useNavigate,useLocation } from 'react-router-dom';

export default function BigBtn(props) {
    const navigate = useNavigate();

  

    function NavigateTo(){
        navigate(props.link,{state:{id:props.text}});
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' })
         
      }
    
    return (
      <div className='' >
        <div className=" flex flex-wrap cursor-pointer  h-48 w-48 rounded-full md:h-60 md:w-60 justify-center items-center" 
        style={{backgroundColor:'#FA8416',borderWidth:'5px',borderColor:'#1F1546'}} 
        onClick={() => NavigateTo(props.text)}>
            <div  style={{color:'white'}} className='text-3xl'>{props.text}</div>
        
        </div>
      </div>
    );
  }